import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Card, Grid, Typography,FormControl, FormGroup, FormLabel } from '@material-ui/core';
import _ from 'lodash';
import CompetitionsWidget from './CompetitionsWidget';
import ActivitesWidget from './ActivitesWidget';
import classNames from 'classnames';


const styles = theme => ({
    card: {
        padding: `${theme.spacing(2)}px`,
        margin: `${theme.spacing(1)}px`,
    },
    indent8: {
        marginLeft: `${theme.spacing(1)}px`
    },
    indent16: {
        marginLeft: `${theme.spacing(2)}px`
    },
    mb8: {
        marginBottom: `${theme.spacing(1)}px`
    },
    legend: {
        textDecoration: 'underline'
    }
});

class ChoixCompetitionsActivites extends React.Component {

    handleFieldChange = (index) => (field) => (event, value, selectedKey) => {
        // console.log('ParticipantsWidget handleFieldChange');
        // console.log ({ index, event, value, selectedKey});
        let data = [...this.props.participants];
        if (field) {
            data[index][field] = value;
        } else {
            data[index] = value;
        }

        this.props.onChange(null, data);
      }

      
    
    render() {
        const { participants,  classes } = this.props;

        const compsObligatoires = _.sortBy(this.props.allCompetitions.filter((c) => c.est_obligatoire), ['date']);
        const compsFacultativesEnLigne = _.sortBy(this.props.allCompetitions.filter((c) => !c.est_obligatoire && !c.est_payable_sur_place), ['date']);
        const compsFacultativesSurPlace = _.sortBy(this.props.allCompetitions.filter((c) => !c.est_obligatoire && c.est_payable_sur_place), ['date']);
        const activitesInscriptables = _.sortBy(this.props.allActivites.filter((a) => a.est_inscription_requise), ['date_du']);

        return (
            participants.map((participant, participantIndex) =>
            <Card key={participantIndex} className={classes.card}>
                <Grid container 
                    direction='row' 
                    justifyContent='space-between'
                    alignItems='center'
                    spacing={1}>
                    <Grid item>
                        <Typography variant="h5" gutterBottom>
                        Participant {participantIndex+1} {participant.membre ? ': ' + participant.membre.nom_prenom : '' }
                        </Typography>
                    </Grid>                    
                </Grid>
                
                <Grid container direction={'column'} spacing={2}>
                   
                   {
                       compsObligatoires && compsObligatoires.length > 0 && 
                       <Grid item>
                       <FormControl component="fieldset" >
                           <Typography variant="h6" >Compétitions obligatoires</Typography>
                           <FormGroup>
                               <div className={classes.indent8}>
                                   <CompetitionsWidget
                                       key={participantIndex}
                                       value={participant.competitions}
                                       allCompetitions={compsObligatoires}
                                       participant={participant}
                                       onChange={this.handleFieldChange(participantIndex)('competitions')}
                                   />
                               </div>
                           </FormGroup>
                       </FormControl>
                       </Grid>
   
                   }

                    {
                        compsFacultativesEnLigne && compsFacultativesEnLigne.length > 0 &&
                        <Grid item>
                        <FormControl component="fieldset">
                            <Typography variant="h6" >Compétitions facultatives</Typography>
                            <FormGroup>
                                <div className={classNames(classes.indent8, classes.mb8)}>
                                    <CompetitionsWidget
                                        key={participantIndex}
                                        value={participant.competitions}
                                        allCompetitions={compsFacultativesEnLigne}
                                        participant={participant}
                                        onChange={this.handleFieldChange(participantIndex)('competitions')}
                                    />
                                </div>
                            </FormGroup>
                        </FormControl>
                        </Grid>    
                    }
                    {
                        compsFacultativesSurPlace && compsFacultativesSurPlace.length > 0 &&
                        <Grid item>
                        <FormControl component="fieldset">
                            <Typography variant="h6" className={classes.mb8}>Compétitions facultatives (payables sur place)</Typography>
                            <Typography variant="body2" className={classNames(classes.indent8, classes.mb8)}>
                                { `À des fins d’estimation des ressources d’arbitrage nécessaires, veuillez indiquer si vous prévoyez participer aux compétitions ci-dessous.`}
                            </Typography>
                            <FormGroup>
                                <div className={classNames(classes.indent8, classes.mb8)}>
                                    <CompetitionsWidget
                                        key={participantIndex}
                                        value={participant.competitions}
                                        allCompetitions={compsFacultativesSurPlace}
                                        participant={participant}
                                        onChange={this.handleFieldChange(participantIndex)('competitions')}
                                    />
                                </div>
                            </FormGroup>
                        </FormControl>
                        </Grid>    
                    }
                    {
                        activitesInscriptables && activitesInscriptables.length > 0 && 
                        <Grid item>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Activités</FormLabel>
                                <FormGroup>
                                    <div className={classes.indent8}>
                                        <ActivitesWidget
                                            key={participantIndex}
                                            value={participant.activites}
                                            allActivites={activitesInscriptables}
                                            participant={participant}
                                            onChange={this.handleFieldChange(participantIndex)('activites')}
                                        />
                                    </div>
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    }
                </Grid>
            </Card>
                
            )
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        
    }, dispatch);
}

function mapStateToProps({tournoi})
{
    return {
        tournoi: tournoi.tournoi
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChoixCompetitionsActivites));