import "core-js/stable";
import "regenerator-runtime/runtime";
import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ErrorBoundary from './Components/ErrorBoundary';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import store from './store';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const history = createBrowserHistory();

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	environment: process.env.REACT_APP_ENVIRONMENT_STAGE,
	integrations: [new Integrations.BrowserTracing({
		routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
		// Send sentry-trace header on requests to these domains
		tracingOrigins: [process.env.REACT_APP_BACKEND_URL.replace('https://','')]
	})],
	normalizeDepth: 5, // Pour redux (Default 3)
  
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 0.8,
  });

const myFallback = <ErrorBoundary />;

const Root = () => {
	
	return (
		<Sentry.ErrorBoundary fallback={myFallback}>
			<Provider store={store}>
					<Router history={history}>
						<div>
							{/* <Route path={'/:tournoiSlug/inscriptionPostale'} component={InscriptionPostale} /> */}
							
							<Route path="/:tournoiSlug" component={App} />
						</div>
					</Router>
			</Provider>
		</Sentry.ErrorBoundary>
	);
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

