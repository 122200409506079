import React, { Component } from 'react';
import {FormControlLabel, Checkbox} from '@material-ui/core';
import _ from 'lodash';
import * as PriceUtils from '../../../utils/priceUtils'

class CompetitionsWidget extends Component {

    //https://blog.sicara.com/custom-nested-forms-validation-javascript-react-2f81cf6aa6e3
    handleFieldChange = (index) => (event, value, selectedKey) => {
        // console.log('ParticipantsWidget handleFieldChange');
        // console.log ({ index, event, value, selectedKey});
        let data = [...this.props.value];
        data[index] = value;

        this.props.onChange(null, data);
    }

    handleActiviteChecked = (activite) => (event, value, selectedKey) => {
        let data = [];

        if (event.target.checked) {
            data = _.concat(this.props.value, [activite]);
        } else {
            data = _.filter(this.props.value, (c) => c._id !== activite._id);
        }
        
        this.props.onChange(null, data);
    }

    render() {
        const allActivites = this.props.allActivites;
        const selectedActivites = this.props.value;
        const participant = this.props.participant;

        return (
            allActivites && allActivites.map((activite, index) => {

                return (<FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedActivites.findIndex(c => c._id === activite._id) > -1}
                            onChange={this.handleActiviteChecked(activite)}
                            value={activite._id} />
                    }
                    label={`${activite.nom} ${PriceUtils.calculateCompetitionPrice(activite, participant.membre).formattedString}`}
                    key={activite._id}
                />);
            })
        )

    }
}

export default CompetitionsWidget;