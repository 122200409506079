import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import ChoixParticipant from './ChoixParticipant';

const styles = theme => ({
    button: {
        marginLeft: `${theme.spacing(1)}px`,
    }
});

class ChoixParticipants extends React.Component {

    handleFieldChange = (index) => (event, value, selectedKey) => {
        // console.log('ParticipantsWidget handleFieldChange');
        // console.log ({ index, event, value, selectedKey});
        let data = [...this.props.participants];
        data[index] = value;

        this.props.onChange(null, data);
      }

      handleRemoveParticipant = (index) => () => {
        let data = [...this.props.participants];
        if (data.length >= index) {
            data.splice(index, 1);
        }
        this.props.onChange(null, data);
      }
    
    render() {
        const { participants, onAjouterParticipant, classes, tournoi } = this.props;

        return (
            <div>
            {
                participants.map((participant, index) => {
                    return (
                        <ChoixParticipant 
                            key={index} 
                            participant={participant}
                            index={index}
                            onChange={this.handleFieldChange(index)}
                            inscriptions={this.props.inscriptions}
                            onRemoveParticipant={this.handleRemoveParticipant(index)}
                            />
                    );
                })
            }
            <Button
                onClick={onAjouterParticipant}
                color="primary"
                variant="contained"
                className={classes.button}
                disabled={participants.length >= tournoi.nb_inscriptions_par_enveloppe}
            >
                Ajouter un participant
            </Button>

            </div>
            
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        
    }, dispatch);
}

function mapStateToProps({tournoi, inscriptions})
{
    return {
        tournoi: tournoi.tournoi,
        inscriptions: inscriptions.inscriptions
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ChoixParticipants));