import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, TextField, Card, List, ListItem, ListItemText, ListItemSecondaryAction, Icon } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { injectStripe } from 'react-stripe-elements';
// import StripeCardsSection from './StripeCardsSection';
import * as PriceUtils from '../../../utils/priceUtils';
import _ from 'lodash';
import CreditCardDetail from "./CreditCardDetail";
const InjectedCreditCard = injectStripe(CreditCardDetail, { withRef: true });

const styles = theme => ({
    card: {
        padding: `${theme.spacing(2)}px`,
        margin: `${theme.spacing(1)}px`,
    },
    total: {
        marginRight: `${theme.spacing(1)}px`,
    },
    iconAndText: {
        display: 'flex',
        alignItems: 'center',
        margin: `${theme.spacing(1)}px`,
        '& span': {
            marginRight: `${theme.spacing(1) / 2}px`,
        }
    },
    aNoter: {
        marginTop: `${theme.spacing(2)}px`
    },
    mb8: {
        marginBottom: `${theme.spacing(1)}px`
    }
});

function getChoix(choix) {
    switch (choix) {
        case 0:
            return 'Non';
        case 1:
            return 'Oui';
        case 2:
            return 'Peut-être'
        default:
            return '';
    }
}

function getTableFixe(participant) {
    switch (participant.table_fixe_type) {
        case 'tableau':
            return 'Près du tableau';
        case 'toilettes':
            return 'Près des toilettes';
        case 'derniere_rangee':
            return 'Dernière rangée';
        case 'hauts_parleurs':
            return 'Près des hauts-parleurs';
        case 'autre':
            return participant.table_fixe_precision;
        default:
            return '';
    }
}

class Sommaire extends React.Component {

    handleFieldChange = (field) => (event, value) => {

        let data = { ...this.props.inscription }
        data[field] = value || event.target.value;

        this.props.onChange(null, data);
    }



    render() {

        const { inscription, classes, inscriptionError, tournoi } = this.props;
        inscription.participants = inscription.participants.map((p) => PriceUtils.appliquerRabais(p, tournoi));
        const { total: grandTotal, formattedString: grandTotalStr } = PriceUtils.calculateInscriptionTotal(inscription);

        return (
            <>
                <Grid container direction="column">
                    <Grid item>
                        <Typography variant="subtitle1" gutterBottom>
                            Veuillez vérifier les informations ci-dessous avant de procéder au paiement. Pour modifier votre inscription, appuyez sur le bouton "Précédent".
                    </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6">Inscriptions</Typography>
                    </Grid>
                    <Grid item>
                        {inscription.participants.map((participant, index) => {
                            let participantTotal = PriceUtils.calculateParticipantTotal(participant);

                            return (
                                <Card key={index} className={classes.card}>
                                    <Grid container
                                        direction='row'
                                        justifyContent='space-between'
                                        alignItems='center'>
                                        <Grid item xs={12} sm={5}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Participant {index + 1} {participant.membre ? ': ' + participant.membre.nom_prenom : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <div className={classes.iconAndText}>
                                                <Icon color="action">email</Icon>
                                                <Typography color="textSecondary">{participant.email}</Typography>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <div className={classes.iconAndText}>
                                                <Icon color="action">phone</Icon>
                                                <Typography color="textSecondary">{participant.telephone}</Typography>
                                            </div>
                                        </Grid>
                                        {
                                            participant.table_fixe &&
                                            <Grid item xs={12}>
                                                <div className={classes.iconAndText}>
                                                    <Icon color="action">event_seat</Icon>
                                                    <Typography color="textSecondary">{getTableFixe(participant)}</Typography>
                                                </div>
                                            </Grid>
                                        }
                                        {
                                            participant.membre && participant.membre.is_accepte_office &&
                                            <Grid item xs={12}>
                                                <div className={classes.iconAndText}>
                                                    <Icon color="action">mood</Icon>
                                                    <Typography color="textSecondary">Accepté d'office</Typography>
                                                </div>
                                            </Grid>
                                        }
                                    </Grid>

                                    <Grid container direction={'column'}>
                                        <Grid item>
                                            <List>
                                                {
                                                    _.filter(participant.competitions, (cc) => cc.choix > 0).map((cc) =>
                                                        <ListItem key={cc.competition._id}>
                                                            <ListItemText
                                                                primary={cc.competition.nom + (cc.competition.est_payable_sur_place ? ' (payable sur place)' : '')}
                                                                secondary={cc.competition.est_payable_sur_place ? 'Choix: ' + getChoix(cc.choix) : ''}
                                                            />
                                                            <ListItemSecondaryAction>
                                                                {cc.competition.est_payable_sur_place ? '' : PriceUtils.calculateCompetitionPrice(cc.competition, participant.membre).formattedString}
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    )
                                                }
                                                {
                                                    participant.activites.map((activite) =>
                                                        <ListItem key={activite._id}>
                                                            <ListItemText
                                                                primary={activite.nom}
                                                            /*secondary={}*/
                                                            />
                                                            <ListItemSecondaryAction>
                                                                {PriceUtils.calculateCompetitionPrice(activite, participant.membre).formattedString}
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    )
                                                }
                                                {
                                                    participantTotal.formattedRabais && participantTotal.formattedRabais.map((fr) => {
                                                        return <Alert severity="success">Ce participant se qualifie pour un rabais de {fr}</Alert>
                                                    })
                                                    // <ListItem>
                                                    //     <ListItemText primary={participantTotal.rabais.nom} />
                                                    //     <ListItemSecondaryAction>
                                                    //         - { participantTotal.formattedRabais}
                                                    //     </ListItemSecondaryAction>
                                                    // </ListItem>

                                                }
                                                <ListItem>
                                                    <ListItemText primary="&nbsp;"/>
                                                    <ListItemSecondaryAction>
                                                        <Typography
                                                        //variant={"h6"}
                                                        >
                                                            Sous-total: {participantTotal.formattedString}
                                                        </Typography>
                                                    </ListItemSecondaryAction>
                                                </ListItem>

                                            </List>

                                        </Grid>

                                    </Grid>

                                </Card>
                            );
                        }


                        )}

                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"h5"} align={'right'} className={classes.total}>
                            Total: {grandTotalStr}
                        </Typography>
                    </Grid>
                </Grid>

                {
                    grandTotal > 0 && <Grid container direction="column">
                        <Grid item>
                            <Typography variant="h6" className={classes.mb8}>Paiement</Typography>
                        </Grid>

                        <Grid item>
                            <TextField
                                label="Nom du titulaire de la carte"
                                id="nom_titulaire"
                                name="nom_titulaire"
                                value={inscription.nom_titulaire}
                                onChange={this.handleFieldChange('nom_titulaire')}
                                required
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <InjectedCreditCard
                                //initialValues={{
                                // creditCardNumber: "42424242424242424242",
                                // onCardNumberBlur: this.onFieldBlur("number"),  // https://codesandbox.io/s/stripe-n41kb
                                //}}
                                setFormComplete={(isComplete) => { this.handleFieldChange('isCardFormComplete')({ target: { value: isComplete } }, isComplete) }}
                                setBillingName={name => {
                                    //this.props.onRequiredChange("name", name.target.value);
                                }}
                                stripe={this.props.stripe}
                                ref={this.props.innerRef}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Commentaires"
                                id="commentaires"
                                name="commentaires"
                                multiline
                                rows={3}
                                value={inscription.commentaires}
                                onChange={this.handleFieldChange('commentaires')}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.aNoter}>
                                À noter que le montant ci-dessus ne sera débité de votre carte qu'une fois la période d'inscriptions terminée, et suite au tirage au sort s'il y a lieu.
                    </Typography>
                        </Grid>
                        {
                            inscriptionError && inscriptionError.code === 406 &&
                            <Grid item>
                                <Typography align={'center'} variant={'subtitle2'} color={"error"}>
                                    {`Erreur avec la carte: ${inscriptionError.message}`}
                                </Typography>
                            </Grid>
                        }

                    </Grid>
                }

            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ tournoi, inscription }) {
    return {
        tournoi: tournoi.tournoi,
        inscriptionError: inscription.error,
    }
}

export default withStyles(styles)(injectStripe((connect(mapStateToProps, mapDispatchToProps)(Sommaire))));