import {combineReducers} from 'redux';

import tournoi from './tournoi.reducer';
import inscriptions from './inscriptions.reducer';
import inscription from './inscription.reducer';

const createReducer = () =>
    combineReducers({
        tournoi,
        inscriptions,
        inscription
    });

export default createReducer;
