const _ = require('lodash');

export function estMembreDejaInscrit(membre, inscriptions) {
    if (!membre || !inscriptions || inscriptions.length === 0)
  {
      return false;
  }
  
  return _.flatMap(inscriptions, (i) => i.participants).findIndex((p) => p.membre.no_membre === membre.no_membre && p.est_actif) > -1;
}

export function estSelectionParticipantValide(membre, inputText, estChoixRenseigne) {
  if (estChoixRenseigne && inputText && inputText.length > 0 && !membre) {
    return false;
  } 
  return true;
}