import React, { Component } from 'react';
import { Card, Icon, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import GoogleMap from 'google-map-react';

const styles = theme => ({
	root: {
		border: '1px solid ' + theme.palette.divider,
		boxShadow: 'none',
		borderRadius: 8,
		width: '100%',
		height: '300px',

	},
	marker: {
		color: 'red',
		 top: '50%',
		 left: '50%',
		 transform: 'translate(-50%, -50%)'
	}
});

// const mapStyles = [
// 	{
// 		'featureType': 'administrative',
// 		'elementType': 'labels.text.fill',
// 		'stylers': [
// 			{
// 				'color': '#444444'
// 			}
// 		]
// 	},
// 	{
// 		'featureType': 'landscape',
// 		'elementType': 'all',
// 		'stylers': [
// 			{
// 				'color': '#f2f2f2'
// 			}
// 		]
// 	},
// 	{
// 		'featureType': 'poi',
// 		'elementType': 'all',
// 		'stylers': [
// 			{
// 				'visibility': 'off'
// 			}
// 		]
// 	},
// 	{
// 		'featureType': 'road',
// 		'elementType': 'all',
// 		'stylers': [
// 			{
// 				'saturation': -100
// 			},
// 			{
// 				'lightness': 45
// 			}
// 		]
// 	},
// 	{
// 		'featureType': 'road.highway',
// 		'elementType': 'all',
// 		'stylers': [
// 			{
// 				'visibility': 'simplified'
// 			}
// 		]
// 	},
// 	{
// 		'featureType': 'road.arterial',
// 		'elementType': 'labels.icon',
// 		'stylers': [
// 			{
// 				'visibility': 'off'
// 			}
// 		]
// 	},
// 	{
// 		'featureType': 'transit',
// 		'elementType': 'all',
// 		'stylers': [
// 			{
// 				'visibility': 'off'
// 			}
// 		]
// 	},
// 	{
// 		'featureType': 'water',
// 		'elementType': 'all',
// 		'stylers': [
// 			{
// 				'color': '#039be5'
// 			},
// 			{
// 				'visibility': 'on'
// 			}
// 		]
// 	}
// ];

function Marker({ text, classes }) {

	return (
		<Tooltip title={text} placement="top">
			<Icon className={classNames(classes.marker)}>place</Icon>
		</Tooltip>
	);
}

class MapContainer extends Component {
	render() {
		const { lieu_gmaps, classes } = this.props;
		return (
			<Card className={classNames(classes.root)}>
				<GoogleMap
					bootstrapURLKeys={{
						key: process.env.REACT_APP_GMAPS_API_KEY
					}}
					defaultZoom={14}
					defaultCenter={[lieu_gmaps.geometry.location.lat, lieu_gmaps.geometry.location.lng]}
					options={{
						//styles: mapStyles
					}}
				>
					{
						(lieu_gmaps && 
							<Marker
								text={lieu_gmaps.formatted_address}
								lat={lieu_gmaps.geometry.location.lat}
								lng={lieu_gmaps.geometry.location.lng}
								classes={classes}
						/>		
						)
					}
					
				</GoogleMap>

			</Card>
		);
	}
}

export default withStyles(styles, { withTheme: true })(MapContainer);
