import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { StripeNumberTextField, StripeExpirationTextField, StripeCvcTextField } from "./commonTextFields";

export default class CreditCardDetail extends React.Component {
  state = {
    creditCardNumberComplete: false,
    expirationDateComplete: false,
    cvcComplete: false,

    cardNameError: false,
    cardNumberError: false,
    expiredError: false,
    cvcError: false
  };

  onElementChange = (field, errorField) => ({
    complete,
    error = { message: null }
  }) => {
    this.setState({ [field]: complete, [errorField]: error.message });
    const expectedState = { ...this.state };
    expectedState[field] = complete;
    this.props.setFormComplete(
      expectedState.creditCardNumberComplete &&
        expectedState.cvcComplete &&
        expectedState.expirationDateComplete
    );
  };

  render() {
    const { cardNumberError, expiredError, cvcError } = this.state;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} id={"card-number"}>
                <StripeNumberTextField
                    error={Boolean(cardNumberError)}
                    labelErrorMessage={cardNumberError}
                    onChange={this.onElementChange(
                        "creditCardNumberComplete",
                        "cardNumberError"
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <StripeExpirationTextField
                    error={Boolean(expiredError)}
                    labelErrorMessage={expiredError}
                    onChange={this.onElementChange(
                        "expirationDateComplete",
                        "expiredError"
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <StripeCvcTextField
                    error={Boolean(cvcError)}
                    labelErrorMessage={cvcError}
                    onChange={this.onElementChange(
                        "cvcComplete",
                        "cvcError"
                    )}
                />
            </Grid>
        </Grid>
    );
  }
}

CreditCardDetail.propTypes = {
  setBillingName: PropTypes.func.isRequired,
  setFormComplete: PropTypes.func.isRequired
};
