import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Stepper, Step, StepLabel, Button, Typography, CircularProgress } from '@material-ui/core';
import { injectStripe} from 'react-stripe-elements';
import { ValidatorForm } from 'react-material-ui-form-validator';
import _ from 'lodash';
import * as Sentry from "@sentry/react";

import ChoixParticipants from '../Components/InscriptionSteps/Participants/ChoixParticipants';
import Confirmation from '../Components/InscriptionSteps/Confirmation/Confirmation';
import ChoixCompetitionsActivites from '../Components/InscriptionSteps/Options/ChoixCompetitionsActivites';
import Sommaire from '../Components/InscriptionSteps/Paiement/Sommaire';
import * as InscriptionActions from '../store/actions/inscription.actions';
import { estMembreDejaInscrit } from '../utils/validators';
import * as PriceUtils from '../utils/priceUtils';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3*2))]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    maxWidth: 960,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'left'
  },
  stepper: {
    padding: `${theme.spacing(3)}px 0 ${theme.spacing(5)}px`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  buttonProgress: {
    //color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    // marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
});

const steps = ['Participants', 'Options', 'Paiement'];



class Inscription extends Component {
  state = {
    activeStep: 0, 
    isLoaded: false,
    loading: false,
    form: {
      participants: [],
      mode_paiement: 'stripe',
      nom_titulaire: '',
      commentaires: '',
      date_reception: new Date(),
      total: 0,
      isCardFormComplete: false,
      cardInfo: {
        card: { focused: false, empty:true, error:false },
        expiration: { focused: false, empty:true, error:false },
        cvv: { focused: false, empty:true, error:false }
      }
    },
    
  };

  nouveauParticipant() {
    let competitions = [];
    /* Pré-load chaque compétition avec choix par défaut */
    //console.log('NouveauParticipant');
    //console.log(this.props.tournoi);
    if (this.props.tournoi.competitions && this.props.tournoi.competitions.length > 0) {
      competitions = this.props.tournoi.competitions.map((c) => { return { choix: c.est_obligatoire ? 1 : 0, competition: c }});
    }
    //console.log(competitions);
    return {
      membre: null,
      nom: '',
      email: '',
      telephone: '',
      table_fixe: false,
      table_fixe_type: '',
      table_fixe_precision: '',
      est_actif: true,
      competitions: competitions,
      activites: []
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tournoi && this.props.tournoi._id && !this.state.isLoaded) {
      
      this.setState({ ...this.state, isLoaded: true, form: {...this.state.form, participants: [ this.nouveauParticipant() ]}});
      
      //this.setState({ ...this.state, isLoaded: true, form: {...this.state.form}});
    }
  }

  getStepContent(step) {
    switch (step) {
      case 0:
        return <ChoixParticipants
          participants={this.state.form.participants}
          onChange={this.handleFieldChange('participants')}
          onAjouterParticipant={this.handleAjouterParticipant}
           />;
      case 1:
        return <ChoixCompetitionsActivites
          participants={this.state.form.participants}
          onChange={this.handleFieldChange('participants')}
          allCompetitions={this.props.tournoi.competitions}
          allActivites={this.props.tournoi.activites} />;
      case 2:
        return <Sommaire 
        onChange={this.handleFieldChange('form')} 
        inscription={this.state.form}
        stripe={this.props.stripe}
         />;
      default:
        throw new Error('Unknown step');
    }
  }

  isStepValid(step) 
  {
    var {form} = this.state;
    var reducer = (accumulator, currentValue) => Boolean(accumulator) && Boolean(currentValue);

    switch (step) {
      // CHoix participant
      case 0:
        return form.participants && form.participants.length > 0 && form.participants.map((p) => 
            p.membre && 
            !estMembreDejaInscrit(p.membre, this.props.inscriptions) &&
            p.email && 
            p.email.length > 0 && 
            p.telephone 
            && p.telephone.length > 0
            && (!p.table_fixe || (p.table_fixe && p.table_fixe_type !== ''))
        ).reduce(reducer);
        // Options
      case 1:
        return form.participants && form.participants.length > 0 && form.participants.map((p) =>
        p.competitions.length >= 1
        ).reduce(reducer);
        // Paiement
      case 2:
        return (PriceUtils.calculateInscriptionTotal(form).total === 0 || 
        (form.nom_titulaire && form.nom_titulaire.length > 0 && //form.cardInfo && 
          form.isCardFormComplete
          // !form.cardInfo.card.error && !form.cardInfo.card.empty &&
          // !form.cardInfo.expiration.error && !form.cardInfo.expiration.empty &&
          // !form.cardInfo.cvv.error && !form.cardInfo.cvv.empty
          ));
      default:
        return false;
    }
  }

  handleFieldChange = (field) => (event, value, selectedKey) => {
    //  console.log('NouvelleInscription handleFieldChange');
    //  console.log ({ field, event, value, selectedKey});

    if (field === 'form') {
      let form = _.merge(this.state.form, value);
      this.setState({ form: form });
    } else {
      let form = { ...this.state.form };
      // use here event or value of selectedKey depending on your component's event
      form[field] = value;
      this.setState({ form: form });
    }
    if (Sentry) {
      Sentry.configureScope((scope) => {
        if (this.state?.form?.participants && this.state.form.participants.length > 0) {
          scope.setUser({"email": this.state.form.participants[0].email});
        }
        scope.setExtra("tournoi", this.props.tournoi.nom);
        scope.setExtra("inscription", this.state.form);
      });
    }
    
  }

  handleAjouterParticipant = () => {

    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        participants: [...this.state.form.participants, this.nouveauParticipant()]
      }
    });
  }


  handleNext = () => {
      this.setState(state => ({
        activeStep: state.activeStep + 1,
      }));
      window.scrollTo(0,0);
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
    window.scrollTo(0,0);
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    
    //Paiement
    if (this.state.activeStep === steps.length - 1) {
      this.props.saveInscription(this.state.form, this.props.stripe, this.props.tournoi);
    }  

	};

  render() {
    const { classes, isInscriptionSaving, isInscriptionSaved, inscription, inscriptionError, tournoi } = this.props;
    const { activeStep } = this.state;

    return (
      <React.Fragment>

        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Inscription
                </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {isInscriptionSaved ? (
              <React.Fragment>
                <Confirmation inscription={inscription} />
              </React.Fragment>
            ) : (inscriptionError && inscriptionError.code !== 406) ? (
              <div>
                Une erreur est survenue lors du traitement de votre inscription. Veuillez réessayer. Si le problème persiste, veuillez <a href={`mailto:${tournoi.organisateur.email}`}>contacter l'organisateur.</a>
                <p></p>
                <p>Nous sommes désolés pour cet inconvénient</p>
                </div>
            ) : (
                <React.Fragment>
                  <ValidatorForm onSubmit={this.handleSubmit}>

                  {this.getStepContent(activeStep)}

                    <div className={classes.buttons}>
                      {activeStep !== 0 && (
                        <Button onClick={this.handleBack} className={classes.button}>
                          Précédent
                        </Button>
                      )}
                      {
                        activeStep === steps.length - 1 ?
                        <div className={classes.wrapper}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          disabled={!this.isStepValid(activeStep) || isInscriptionSaving}
                      >
                      Soumettre
                      </Button>
                      {isInscriptionSaving && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                      :
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleNext}
                        className={classes.button}
                        disabled={!this.isStepValid(activeStep)}
                      >
                        Suivant
                      </Button>
                      }
                      
                    </div>
                  </ValidatorForm>
                </React.Fragment>
              )}
          </React.Fragment>
        </Paper>
      </React.Fragment>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    saveInscription : InscriptionActions.saveInscription
  }, dispatch);
}

function mapStateToProps({ tournoi, inscription, inscriptions }) {
  return {
    tournoi: tournoi.tournoi,
    inscription: inscription.inscription,
    isInscriptionSaving: inscription.loading,
    isInscriptionSaved: inscription.isInscriptionSaved,
    inscriptionError: inscription.error,
    inscriptions: inscriptions.inscriptions
  }
}

export default withStyles(styles)(injectStripe(connect(mapStateToProps, mapDispatchToProps)(Inscription)));