import * as Actions from '../actions';

const initialState = {
	tournoi: {
		competitions: [],
		activites: []
    },
    isLoading: true,
    error: null
};

const tournoi = function (state = initialState, action) {
	switch ( action.type )
	{
        case Actions.FETCH_TOURNOI_BEGIN:
        {
            return {
                ...state,
                isLoading: true,
                error: null
            };
        }
	    case Actions.FETCH_TOURNOI_SUCCESS:
	    {
		    return {
			    ...state,
			    isLoading: false,
			    tournoi: action.payload
		    };
	    }
	    case Actions.FETCH_TOURNOI_FAILURE:
	    {
		    return {
			    ...state,
			    isLoading: false,
			    error: action.payload.error,
                tournoi: null
		    };
	    }
		default:
		{
			return state
		}
	}
};

export default tournoi;

