import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Typography, Grid, Paper, FormControl, FormGroup,FormControlLabel, Checkbox, Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import * as Actions from '../store/actions';
import _ from 'lodash';
import "react-table/react-table.css";
import moment from 'moment';


const styles = theme => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3*2))]: {
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
          padding: theme.spacing(3),
        },
        maxWidth: 960,
        //minHeight: theme.spacing(64),
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left'
      },
      ml16: {
        marginLeft: `${theme.spacing(2)}px`
    },
    radio: {
        padding: `${theme.spacing(1)}px`
    }   
});


class Annulation extends Component {
    state = {
        selectedParticipants: [],
        loading: true
    }

    componentDidMount()
	{
	}

    componentDidUpdate(prevProps, prevState)
    {
        if (this.props.tournoi && this.props.tournoi._id && (prevProps.tournoi !== this.props.tournoi)) {
            this.props.fetchInscriptionForAnnulation(this.props.tournoi, this.props.match.params.participantId);
        }
    }

    getParticipantsAdmissibles(inscription) {
        if (inscription && inscription.participants && inscription.participants.length > 0) {
            if (inscription.participants[0]._id === this.props.match.params.participantId) {
                // Présenter au demandeur tous les participants
                return inscription.participants;
            } else {
                return _.filter(inscription.participants, (p) => p._id === this.props.match.params.participantId);
            }
        }
        return [];
    }

    handleParticipantChecked = (participant) => (event, value, selectedKey) => {
        var selectedParticipants = [...this.state.selectedParticipants];

        if (event.target.checked && selectedParticipants.findIndex((p) => p._id === participant._id) < 0) {
            selectedParticipants=  [...selectedParticipants, participant];
        } else {
            _.remove(selectedParticipants, (p) => p._id === participant._id);
        }

        this.setState({ selectedParticipants: selectedParticipants});
    }

    handleSubmit = () => {
        this.props.annulerInscription(this.props.inscription, this.state.selectedParticipants, this.props.match.params.annulationGuid);
    }

    render()
    {
        const { classes, inscription, tournoi, isAnnulationSaved, loading } = this.props;
        const participants = this.getParticipantsAdmissibles(inscription);
        const isTournoiTermine = moment(tournoi.date_au).isBefore(moment());
        const isMultiComp = tournoi.competitions.length > 1;
        const isDataLoaded = (!loading && (isAnnulationSaved || (inscription && tournoi)));

        return (
            <Paper className={classes.paper}>
                {
                    !isDataLoaded && <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        <CircularProgress />
                                    </Grid>
                                </Grid>
                            </Grid>
                    </Grid>
                }
                {
                    isDataLoaded && <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={"h4"} align={'left'} gutterBottom>
                            Désistement
                        </Typography>
                        <Typography variant={"subtitle1"}>
                            Tournoi { tournoi.nom }
                        </Typography>
                    </Grid>
                    {
                        isTournoiTermine && <>
                        <Grid item xs={12}>
                            Ce tournoi est terminé, il n'est donc plus possible d'annuler votre inscription.
                        </Grid>
                        </>
                    }
                    {
                        !isTournoiTermine && isAnnulationSaved && <>
                            <Grid item xs={12}>
                                Nous avons bien reçu votre demande de désistement.
                            </Grid>
                            <Grid item xs={12}>
                                Un courriel de confirmation vous a été envoyé à l'adresse courriel renseignée lors de votre inscription.
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={32} justifyContent="center">
                                    <Grid item>
                                        <Button variant="contained" color="primary"
                                            href={`/${tournoi.slug}`}>
                                            Retour à la page d'accueil
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid> 
                        </>
                    }
                    {
                        !isTournoiTermine && !isAnnulationSaved && <>
                        {
                            !isMultiComp && <>
                                        <Grid item xs={12}>
                                            Choisissez les participants pour lesquels vous souhaitez annuler l'inscription au tournoi {tournoi.nom}
                                        </Grid>
                            </>
                        }
                        {
                            isMultiComp && <>
                                        <Grid item xs={12}>
                                            Choisissez les participants pour lesquels vous souhaitez annuler l'inscription À TOUTES LES COMPÉTITIONS du tournoi {tournoi.nom}
                                        </Grid>
                                        <Grid item xs={12}>
                                            Si vous souhaitez plutôt modifier le choix de vos compétitions, veuillez communiquer avec <a href={`mailto:${tournoi.organisateur.email}`}>l'organisateur du tournoi</a>.
                                        </Grid>
                            </>
                        }
                    
                    <Grid item xs={12}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            {/* <FormLabel component="legend" className={classes.mb8}>Participants</FormLabel> */}
                            <FormGroup>
                                <Grid container>
                                
                                { participants && participants.map((participant, index) => 
                                    <Grid item xs={12} key={index}>
                                        <div className={classNames(classes.indent8, classes.mb8)}>
                                            <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.selectedParticipants.findIndex(p => p._id === participant._id) > -1}
                                                    onChange={this.handleParticipantChecked(participant)}
                                                    value={participant._id}
                                                    disabled={!participant.est_actif} />
                                            }
                                            label={`${participant.membre.nom_prenom}` + (!participant.est_actif ? ' - Désisté(e)' : '') }
                                            />
                                        </div>
                                    </Grid>
                                )}
                                </Grid>
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item>
                                <Button variant="contained" color="primary"
                                    disabled={this.state.selectedParticipants.length === 0}
                                    onClick={this.handleSubmit}>
                                    Confirmer le désistement
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid> 
                        </>
                    }
                    
            </Grid>
                }
            
        </Paper>
        );
                
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        fetchInscriptionForAnnulation: Actions.fetchInscriptionForAnnulation,
        annulerInscription: Actions.annulerInscription
    }, dispatch);
}

function mapStateToProps({tournoi, inscription})
{
    return {
        tournoi : tournoi.tournoi,
        inscription: inscription.inscription,
        isAnnulationSaved: inscription.isAnnulationSaved,
        loading: inscription.loading
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Annulation));