import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const styles = theme => ({
   
});


class Confirmation extends React.Component {
    
    handleFieldChange = (field) => (event, value) => {

        let data = { ...this.props.inscription }
        data[field] = value || event.target.value;
        
        this.props.onChange(null, data);
    }
	
	render() {

        const { tournoi, inscription } = this.props;

		return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        Merci !
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        Nous confirmons réception de votre demande d'inscription.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        Vous recevrez sous peu un courriel de confirmation contenant les détails de votre inscription et un lien personnalisé vous permettant d'annuler votre inscription au besoin.
                    </Typography>
                </Grid>

                {
                    inscription.participants?.find(p => p.table_fixe && !p.membre?.table_fixe) && 
                    <Grid item xs={12}>
                        <Alert severity="info">
                            <AlertTitle>Rappel</AlertTitle>
                            Si vous avez besoin d'une table fixe <strong>permanente</strong>, assurez-vous d'en informer aussi la directrice de la Commission du classement si vous ne l'avez pas déjà fait.
                            </Alert>
                    </Grid>
                }
                
                {
                    tournoi.organisateur && tournoi.organisateur.adresse &&
                    <Grid item xs={12}>
                        <Typography gutterBottom>
                            Pour toute question concernant votre inscription, veuillez contacter :
                        </Typography>

                        <Typography>
                            {tournoi.organisateur.prenom} {tournoi.organisateur.nom}
                        </Typography>
                        <Typography>
                            {tournoi.organisateur.email}
                        </Typography>
                        <Typography>
                            {tournoi.organisateur.telephone}
                        </Typography>
                        {/* <Typography>
                            {tournoi.organisateur ? tournoi.organisateur.adresse.split('\n').map((line, index) => <span key={index}>{line}<br/></span>) : ''}
                        </Typography> */}
                    </Grid>
                }
                
            </Grid>
		);
	}
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        
    }, dispatch);
}

function mapStateToProps({tournoi})
{
    return {
        tournoi: tournoi.tournoi
    }
}

export default withStyles(styles)((connect(mapStateToProps, mapDispatchToProps)(Confirmation)));