import axios from 'axios';
import slugify from 'slugify';
import assert from 'assert';
import * as PriceUtils from '../../utils/priceUtils';

export const SAVE_INSCRIPTION_BEGIN = 'SAVE_INSCRIPTION_BEGIN';
export const SAVE_INSCRIPTION_SUCCESS = 'SAVE_INSCRIPTION_SUCCESS';
export const SAVE_INSCRIPTION_FAILURE = 'SAVE_INSCRIPTION_FAILURE';


export const FETCH_INSCRIPTION_FOR_ANNULATION_BEGIN = 'FETCH_INSCRIPTION_FOR_ANNULATION_BEGIN';
export const FETCH_INSCRIPTION_FOR_ANNULATION_SUCCESS = 'FETCH_INSCRIPTION_FOR_ANNULATION_SUCCESS';
export const FETCH_INSCRIPTION_FOR_ANNULATION_FAILURE = 'FETCH_INSCRIPTION_FOR_ANNULATION_FAILURE';

export const ANNULER_INSCRIPTION_BEGIN = 'ANNULER_INSCRIPTION_BEGIN';
export const ANNULER_INSCRIPTION_SUCCESS = 'ANNULER_INSCRIPTION_SUCCESS';
export const ANNULER_INSCRIPTION_FAILURE = 'ANNULER_INSCRIPTION_FAILURE';

export function annulerInscription(inscription, participants, annulationGuid) {
	assert(annulationGuid);
	assert(inscription);
	assert(inscription._id);
	assert(participants);

	return (dispatch) => {
		dispatch(annulerInscriptionBegin());

		//console.log(token.token);
		axios.post(process.env.REACT_APP_BACKEND_URL + `/inscriptions/${inscription._id}/annulation`,
		{
			annulationGuid: annulationGuid,
			participants: participants.map(p => p._id)
		}
			).then((resp) => {
				dispatch(annulerInscriptionSuccess(null));
				return resp;
			})
			.catch((err) => {
				dispatch(annulerInscriptionSuccess(err));
			});
		}

}

export const annulerInscriptionBegin = () => ({
	type: ANNULER_INSCRIPTION_BEGIN
});

export const annulerInscriptionSuccess = inscription => ({
	type: ANNULER_INSCRIPTION_SUCCESS,
	payload: inscription
});

export const annulerInscriptionFailure = error => ({
	type: ANNULER_INSCRIPTION_FAILURE,
	payload: { error }
});


export function fetchInscriptionForAnnulation(tournoi, participantId) {
	assert(participantId);
	assert(tournoi);
	assert(tournoi._id);

	return (dispatch) => {
		dispatch(fetchInscriptionForAnnulationBegin());

		//console.log(token.token);
		axios.get(process.env.REACT_APP_BACKEND_URL + `/inscriptions?tournoi=${tournoi._id}&participant=${participantId}`
			).then((resp) => {
				//console.log({resp});
				dispatch(fetchInscriptionForAnnulationSuccess(resp.data.data && resp.data.data.length > 0 ? resp.data.data[0] : null));
				return resp;
			})
			.catch((err) => {
				//console.log({err});
				dispatch(fetchInscriptionForAnnulationFailure(err));
			});
		}
}

export const fetchInscriptionForAnnulationBegin = () => ({
	type: FETCH_INSCRIPTION_FOR_ANNULATION_BEGIN
});

export const fetchInscriptionForAnnulationSuccess = inscription => ({
	type: FETCH_INSCRIPTION_FOR_ANNULATION_SUCCESS,
	payload: inscription
});

export const fetchInscriptionForAnnulationFailure = error => ({
	type: FETCH_INSCRIPTION_FOR_ANNULATION_FAILURE,
	payload: { error }
});

export const saveInscriptionBegin = () => ({
	type: SAVE_INSCRIPTION_BEGIN
});

export const saveInscriptionSuccess = inscription => ({
	type: SAVE_INSCRIPTION_SUCCESS,
	payload: inscription
});

export const saveInscriptionFailure = error => ({
	type: SAVE_INSCRIPTION_FAILURE,
	payload: { error }
});

export function saveInscription(inscription, stripe, tournoi)
{
	assert(tournoi);
	assert(tournoi._id);
	// console.log('saveInscription');
    // console.log({ inscription, stripe });
	
	inscription.tournoi = tournoi._id;
	inscription.grand_total = PriceUtils.calculateInscriptionTotal(inscription).total;
		
    return (dispatch) => {
		dispatch(saveInscriptionBegin());

		if (inscription.grand_total > 0) {
			stripe.createToken({name: "token_" + slugify(inscription.nom_titulaire)})
			.then((token) => {
				//console.log(token.token);
				axios.post(process.env.REACT_APP_BACKEND_URL + '/inscriptions',
					{
						...inscription, 
						source: 'frontend',
						token: token.token
					}
					).then((resp) => {
						//console.log({resp});
						dispatch(saveInscriptionSuccess(resp.data));
						return resp;
					})
					.catch((err) => {
						//console.log({err});
						dispatch(saveInscriptionFailure(err.response.data));
					});
			})
			.catch((err) => {
				dispatch(saveInscriptionFailure(err));
			});
		} else {
			axios.post(process.env.REACT_APP_BACKEND_URL + '/inscriptions',
					{
						...inscription, 
					}
					).then((resp) => {
						//console.log({resp});
						dispatch(saveInscriptionSuccess(resp.data));
						return resp;
					})
					.catch((err) => {
						//console.log({err});
						dispatch(saveInscriptionFailure(err.response.data));
					});
		}
		
    }
    // const request = axios.post(process.env.REACT_APP_BACKEND_URL + '/inscriptions', inscription);
    
    // return (dispatch) => {
	//     dispatch(saveInscriptionBegin());

	//     request.then((response) => {
	// 	    dispatch(saveInscriptionSuccess(response.data.data[0]));
	// 	    return response.data.data[0];
    //     })
    //     .catch((error) => {
    //         dispatch(saveInscriptionFailure(error));
    //         return error;
    //     });
    // }
}
