import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import Bulletin from './Bulletin';
import { CssBaseline } from '@material-ui/core';

const styles = theme => ({
  noBreak: {
    'page-break-inside': 'avoid',
  },
});

class Bulletins extends Component {
 
  createTable = () => {
    let table = []

    // Outer loop to create parent
    for (let i = 0; i < this.props.tournoi.nb_inscriptions_par_enveloppe || 0; i++) {
      
        table.push(
         <div style={{'width':'50%', 'display':'inline-block', 'padding':'16px'}}>
          <Bulletin tournoi={this.props.tournoi}/>
         </div>
        )
            
    }
    return table;
  }

  render() {
    const { tournoi } = this.props;
    
    return (
      <div>
        <CssBaseline />
        
      { tournoi && tournoi.nb_inscriptions_par_enveloppe &&  (
          // <Grid container direction={'row'}>
           <> 
            {this.createTable()}
          </>
          // </Grid>
      )}
      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

function mapStateToProps({ tournoi }) {
  return {
    tournoi: tournoi.tournoi
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Bulletins));