import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const styles = theme => ({
    
});

class ErrorBoundary extends React.Component {

    render() {

        return (
        
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h2">
                        Erreur
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        Une erreur est survenue lors de l'enregistrement de votre inscription. Veuillez réessayer plus tard. 
                    </Typography>
                </Grid>
            </Grid>

        );
    }
}

export default withStyles(styles)(ErrorBoundary);