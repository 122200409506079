import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ReactTable from "react-table";
import "react-table/react-table.css";


const styles = theme => ({
    
});

class ListeJoueurs extends React.Component {

  
    render() {

        const { data, competitions, minRows, afficherRangs, afficherCompetitions } = this.props;

        var columns = [
            {
                Header    : "",
                accessor  : "index",
                // filterable: true,
                className : "font-bold",
                width: 40,
            },
            {
                Header    : "Nom",
                accessor  : "nom_prenom",
                // filterable: true,
                className : "font-bold",
                minWidth: 200
            },
            {
                Header    : "Club",
                accessor  : "club",
                // filterable: true,
                minWidth: 40,
                maxWidth     : 100
            },
            {
                Header    : "Série",
                accessor  : "serie",
                // filterable: true
                minWidth: 40,
                maxWidth     : 100
                
            },
            {
                Header    : "Classe",
                accessor  : "classe",
                // filterable: true
                minWidth: 40,
                maxWidth     : 100
            },
            {
                Header    : "Catégorie",
                accessor  : "cat_fisf",
                minWidth: 40,
                maxWidth     : 100
                // filterable: true
            }
        ];

        if (!afficherRangs) {
            columns.shift();
        }

        if (afficherCompetitions) {
            for (let i =0; i < competitions.length; i++) {
                let comp = competitions[i];
                columns.push({
                    Header: comp.nom,
                    columns: [
                        {
                            Header: (info) => {
                                return info.data && info.data.reduce((sum, row) => row[`comp_${comp._id}`] ? sum + 1 : sum, 0)
                            },
                            accessor: `comp_${comp._id}`,
                            Cell: row => <div style={{textAlign: "center"}}>{row.value}</div>
                        }
                    ]
                    
                })
            }
        }

        var tableData = competitions && (
            data.map((inscriptionParticipant, index) => {
                return {
                    index: index+1,
                    ...inscriptionParticipant.participant.membre,
                    ...inscriptionParticipant.participant.competitions.filter((cc) => cc.choix === 1).map((cc) => cc.competition).reduce((map, obj) => {
                        map[`comp_${obj._id}`] = 'X';
                        return map;
                    }, {})
                }
            }) || []);

        return  (<ReactTable
        className={classNames("-striped -highlight border-0")}
        getTrProps={(state, rowInfo, column) => {
            //console.log(rowInfo);
            if (rowInfo && rowInfo.row) {
                return {
                    className: classNames('notempty')
                    // className: classNames(classes.row, "cursor-pointer"),
                    // onClick  : (e, handleOriginal) => {
                    //     if ( rowInfo )
                    //     {
                    //         openEditContactDialog(rowInfo.original);
                    //     }
                    // }
                }
            }
            return {};
        }}
        data={tableData}
        columns={columns}
        defaultPageSize={400}
        noDataText="Il n'y a aucun joueur"
        showPaginationBottom={false}
        showPageSizeOptions={false}
        minRows={minRows || 15}
    />)
    }
}

export default withStyles(styles)((ListeJoueurs));