import * as Actions from '../actions';

const initialState = {
	inscriptions: [],
    isLoading: true,
    error: null
};

const inscriptions = function (state = initialState, action) {
	switch ( action.type )
	{
        case Actions.FETCH_INSCRIPTIONS_BEGIN:
        {
            return {
                ...state,
                isLoading: true,
                error: null
            };
        }
	    case Actions.FETCH_INSCRIPTIONS_SUCCESS:
	    {
		    return {
			    ...state,
			    isLoading: false,
			    inscriptions: action.payload
		    };
	    }
	    case Actions.FETCH_INSCRIPTIONS_FAILURE:
	    {
		    return {
			    ...state,
			    isLoading: false,
			    error: action.payload.error,
                inscriptions: null
		    };
	    }
		default:
		{
			return state
		}
	}
};

export default inscriptions;

