import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from '@material-ui/core';
//import moment from 'moment/min/moment-with-locales';
import moment from 'moment'
import 'moment/min/locales'
import * as PriceUtils from '../utils/priceUtils';

const styles = theme => ({
    horaire: {
        width: '100%'
    },
    '@media print': {
        horaire: {
            '& li' : {
                paddingTop: '4px !important',
                paddingBottom: '4px !important',
            }
        }
    },
    price: {
        textAlign: 'right'
    }
});

function calculateTarifs(tournoi) {
    var evenements = [];

    // Parties
    if (tournoi.competitions) {
        tournoi.competitions.map(c => {
            evenements.push({
                titre: `${c.nom}`,
                description: c.description,
                tarifs: PriceUtils.calculateRangeTarifs(c).formattedString
            });
            return c;
        });
    }
    
    // Activités
    if (tournoi.activites) {
        tournoi.activites.map(a => {
            evenements.push({
                titre: a.nom,
                description: a.description,
                tarifs: PriceUtils.calculateRangeTarifs(a).formattedString
            });
            return a;
        });
    }
        
    //console.log(sorted);

    return evenements;
}

class Tarifs extends React.Component {

    render() {
        const {tournoi, classes} = this.props;
        moment.locale('fr');

        return (
        tournoi &&     

        <List className={classes.horaire}>
                {
                    calculateTarifs(tournoi).map((evenement, index) => { return (
                        <ListItem key={index}>
                            <ListItemText
                                primary={evenement.titre}
                                secondary={
                                <>
                                    { evenement.tarifs}
                                </>
                                    
                                }>
                            </ListItemText>
                        </ListItem>
                    )})
                }
            </List>

            
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        
    }, dispatch);
}

function mapStateToProps({tournoi})
{
    return {
        tournoi: tournoi.tournoi
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Tarifs));