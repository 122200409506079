import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, FormControlLabel, InputLabel, Select, FormControl, Checkbox, Paper, Card, IconButton, Typography, MenuItem, FormHelperText } from '@material-ui/core';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { Delete as DeleteIcon } from '@material-ui/icons';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { estMembreDejaInscrit, estSelectionParticipantValide } from '../../../utils/validators';

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    container: {
        flexGrow: 1,
        position: 'relative',
    },
    card: {
        padding: `${theme.spacing(2)}px`,
        margin: `${theme.spacing(1)}px`,
    },
    chip: {
        margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
    },
    inputRoot: {
        flexWrap: 'wrap',
    },
    inputInput: {
        width: 'auto',
        flexGrow: 1,
    },
    divider: {
        height: theme.spacing(2),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
});

function renderInput(inputProps, index) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
        <TextValidator
            InputProps={{
                inputRef: ref,
                classes: {
                    root: classes.inputRoot,
                    input: classes.inputInput,
                },
                ...InputProps,
            }}
            {...other}
        />
    );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || {})._id === suggestion._id;

    return (
        <MenuItem
            {...itemProps}
            key={suggestion._id}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {`${suggestion.nom_prenom} (${suggestion.club} #${suggestion.no_membre})`}
        </MenuItem>
    );
}
renderSuggestion.propTypes = {
    highlightedIndex: PropTypes.number,
    index: PropTypes.number,
    itemProps: PropTypes.object,
    selectedItem: PropTypes.string,
    suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};


class ChoixParticipant extends React.Component {
    state = {
        suggestions: [],
        estNomRenseigne: false
    }

    handleFieldChange = (field) => (event, value, selectedKey) => {
        // console.log('ParticipantWidget handleFieldChange');
        // console.log ({ field, event, value, selectedKey});

        let data = { ...this.props.participant };
        value = value || (event.target.type === 'checkbox' ? event.target.checked : event.target.value);
        data[field] = value;
        // you could pass the event here but also null if it is not necessary nor useful
        this.props.onChange(null, data);

        if (field === 'nom') {
            
        }
        
    }

    handleBlur = (field) => (event, value) => {
        if (field === 'nom') {
            this.setState({ estNomRenseigne: true});
        }
    }
    handleFocus = (field) => (event, value) => {
        if (field === 'nom') {
            this.setState({ estNomRenseigne: false})
        }
    }

    handleSelectChange = (field) => (event) => {
        this.handleFieldChange(field)(event, null, null);

        
    }

    handleSelectBlur = (field) => (event) => {
        if (field === 'table_fixe_type') {
            this.setState({ table_fixe_type_touched: true})
        }
    }

    handleAutocompleteItemSelected = item => {
        //console.log('handleAutocompleteItemSelected: ', item);
        
        let membre = this.state.suggestions.find(m => m._id === item._id);

        let newValue = { ...this.props.participant };
        newValue.membre = membre;
        newValue.nom = membre.nom_prenom;

        if (membre.cas_special && membre.cas_special.length > 0) {
            newValue.table_fixe = true;
            newValue.table_fixe_type = 'autre';
            newValue.table_fixe_precision = membre.cas_special;
        }

        this.props.onChange(null, newValue);

    };

    fetchSuggestionsDebounced = _.debounce((nom) => {
        if (!nom || nom.trim() === '') {
            this.setState({ suggestions: [] });
            let newValue = { ...this.props.participant };
            newValue.membre = null;
            newValue.nom = nom;
            this.props.onChange(null, newValue);
        }
        else {
            nom = _.deburr(nom.trim()).toLowerCase();

            axios.get(`${process.env.REACT_APP_BACKEND_URL}/membres?$search=${nom}&$limit=10`)
                .then((res1) => {
                    //console.log({res1});
                    this.setState({ suggestions: res1.data.data || [] })
                });
        }
    }, 500);

    handleNomChange = nom => {
        //console.log({nom});

        this.handleFieldChange('nom')({ target: { value: nom }}, nom, null);

        this.fetchSuggestionsDebounced(nom);
    }

    render() {
        const { participant, index, classes, onRemoveParticipant, inscriptions } = this.props;
        const { suggestions, table_fixe_type_touched } = this.state;

        ValidatorForm.addValidationRule(`isMembreValid_${index}`, (value) => {
            return (participant.membre !== null);
        });

        return (
            participant &&
            <Card className={classes.card}>
                <Grid container 
                    direction='row' 
                    justifyContent='space-between'
                    alignItems='center'>
                    <Grid item>
                        <Typography variant="subtitle1">
                        Participant {index+1} {participant.membre ? ': ' + participant.membre.nom_prenom : '' }
                        </Typography>
                    </Grid>
                    {
                       index !== 0 && 
                        <Grid item>
                            <IconButton className={classes.button} onClick={onRemoveParticipant} aria-label="Delete">
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    }
                    
                </Grid>
                
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Downshift
                                id="downshift-simple"
                                inputValue={participant.nom}
                                onChange={this.handleAutocompleteItemSelected}
                                itemToString={item => (item ? item.nom_prenom : '')}
                                onInputValueChange={this.handleNomChange}
                            >
                                {({
                                    getInputProps,
                                    getItemProps,
                                    getMenuProps,
                                    highlightedIndex,
                                    inputValue,
                                    isOpen,
                                    selectedItem,
                                }) => (
                                        <div className={classes.container}>
                                            {renderInput({
                                                fullWidth: true,
                                                classes,
                                                id: "nom",
                                                name: "nom",
                                                type: 'search',
                                                error: estMembreDejaInscrit(participant.membre, inscriptions) || !(estSelectionParticipantValide(participant.membre, participant.nom, this.state.estNomRenseigne)),
                                                helperText: (estMembreDejaInscrit(participant.membre, inscriptions) ? 'Ce membre est déjà inscrit au tournoi.' : !(estSelectionParticipantValide(participant.membre, participant.nom, this.state.estNomRenseigne)) ? 'Vous devez choisir un participant dans la liste déroulante' : ''),
                                                //validators: ['isEmail', `isMembreValid_${index}`, 'isMembreDejaInscrit'],
                                                //errorMessages: ['Le participant est obligatoire', 'LsaveInscriptionSuccesst obligatoire', 'Ce participant est déjà inscrit au tournoi'],
                                                InputProps: getInputProps({
                                                    placeholder: 'Numéro de membre, nom ou prénom *',
                                                    //onChange: this.handleFieldChange('nom'),
                                                    onBlur: this.handleBlur('nom'),
                                                    onFocus: this.handleFocus('nom')
                                                }, index),
                                            })}
                                            <div {...getMenuProps()}>
                                                {isOpen ? (
                                                    <Paper className={classes.paper} square>
                                                        {suggestions.map((suggestion, index) =>
                                                            renderSuggestion({
                                                                suggestion,
                                                                index,
                                                                itemProps: getItemProps({ item: suggestion }),
                                                                highlightedIndex,
                                                                selectedItem,
                                                            }),
                                                        )}
                                                    </Paper>
                                                ) : null}
                                            </div>
                                        </div>
                                    )}
                            </Downshift>

                        </Grid>
                        <Grid item xs={12}>
                            
                            <TextValidator
                                className="mt-8 mb-16"
                                label="Adresse courriel *"
                                id="email"
                                name="email"
                                value={participant.email}
                                onChange={this.handleFieldChange('email')}
                                fullWidth
                                validators={['required', 'isEmail']}
                                errorMessages={['L\'adresse courriel est obligatoire', 'L\'adresse courriel est invalide']}
                            />
                        </Grid>

                        <Grid item xs={12}>
                        <InputMask mask="999 999-9999" maskChar=" " 
                        value={participant.telephone}
                        onChange={this.handleFieldChange('telephone')}
                        >
                        {
                            () => <TextValidator
                            className="mt-8 mb-16"
                            label="Numéro de téléphone *"
                            id="phone"
                            name="phone"
                            type="text"
                            fullWidth
                            validators={['required', 'matchRegexp:^\\d{3} \\d{3}-\\d{4}$']}
                            errorMessages={['Le numéro de téléphone est obligatoire', 'Le numéro de téléphone est invalide']}
                        />
                        }

                        </InputMask>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={participant.table_fixe}
                                        onChange={this.handleFieldChange("table_fixe")}
                                        value="table_fixe"
                                        color="primary"
                                    />
                                }
                                label="Table fixe"
                            />
                        </Grid>
                            {
                                participant.table_fixe && (
                                    <Grid container item xs={6}>
                                    
                                        <FormControl style={{minWidth: 180}}>
                                            <InputLabel htmlFor="table_fixe_type">Motif de table fixe *</InputLabel>
                                            <Select
                                                value={participant.table_fixe_type}
                                                onChange={this.handleSelectChange('table_fixe_type')}
                                                onBlur={this.handleSelectBlur('table_fixe_type')}
                                                inputProps={{
                                                    name: 'table_fixe_type',
                                                    id: 'table_fixe_type',
                                                }}
                                            >
                                                <MenuItem value="tableau">Près du tableau</MenuItem>
                                                <MenuItem value="toilettes">Près des toilettes</MenuItem>
                                                <MenuItem value="derniere_rangee">Dernière rangée</MenuItem>
                                                <MenuItem value="hauts_parleurs">Près des hauts-parleurs</MenuItem>
                                                <MenuItem value="autre">Autre (préciser)</MenuItem>
                                            </Select>
                                            { table_fixe_type_touched && participant.table_fixe && participant.table_fixe_type === '' && <FormHelperText style={{color: 'red'}}>Veuillez fournir un motif</FormHelperText> }
                                        </FormControl>
                                        
                                    </Grid>)
                            }

                                {participant.table_fixe && participant.table_fixe_type === 'autre' && (
                                        <Grid item xs={12}>
                                            <TextValidator
                                                className="mt-8 mb-16"
                                                validators={ ['required'] }
                                                errorMessages={ ['La précision est obligatoire'] }
                                                //error={participant.table_fixe_precision === ''}
                                                //required={participant.table_fixe === 'autre'}
                                                label="Précision *"
                                                id="table_fixe_precision"
                                                name="table_fixe_precision"
                                                value={participant.table_fixe_precision}
                                                onChange={this.handleFieldChange("table_fixe_precision")}
                                                fullWidth
                                            />
                                        </Grid>
                                        )
                                        }
                        
                    </Grid>
                    </Card>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ tournoi }) {
    return {
        tournoi: tournoi.tournoi
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ChoixParticipant));