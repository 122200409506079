import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from '@material-ui/core';
import _ from 'lodash';
//import moment from 'moment/min/moment-with-locales';
import moment from 'moment'
import 'moment/min/locales'

const styles = theme => ({
    horaire: {
        width: '100%'
    },
    '@media print': {
        horaire: {
            '& li' : {
                paddingTop: '4px !important',
                paddingBottom: '4px !important',
            }
        }
    }
});

function calculateHoraire(tournoi) {
    var evenements = [];

    // Parties
    if (tournoi.competitions) {
        tournoi.competitions.map(c => c.parties.map(p => {
            evenements.push({
                titre: c.parties.length === 1 ? `${c.nom}` : `${c.nom} - ${p.nom}`,
                description: p.description,
                date: p.date_du
            });
            return c;
        }));
    }
    
    // Activités
    if (tournoi.activites) {
        tournoi.activites.map(a => {
            evenements.push({
                titre: a.nom,
                description: a.description,
                date: a.date_du
            });
            return a;
        });
    }
    
    var sorted = _.sortBy(evenements, ['date']);
    
    //console.log(sorted);

    return sorted;
}

class Horaire extends React.Component {

    render() {
        const {tournoi, classes} = this.props;
        moment.locale('fr');

        return (
        tournoi &&     
            <List className={classes.horaire}>
                {
                    calculateHoraire(tournoi).map((evenement, index) => { return (
                        <ListItem key={index}>
                            <ListItemText
                                primary={evenement.titre}
                                secondary={
                                <>
                                {evenement.description && <>
                                    {evenement.description}<br/>
                                </>}
                                {moment(evenement.date).format('LLL')}
                                </>
                                    
                                }>
                            </ListItemText>
                        </ListItem>
                    )})
                }
            </List>
            
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        
    }, dispatch);
}

function mapStateToProps({tournoi})
{
    return {
        tournoi: tournoi.tournoi
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Horaire));