import axios from 'axios' ;

export const FETCH_TOURNOI = '[TOURNOI] GET TOURNOIS';
export const FETCH_TOURNOI_BEGIN = '[TOURNOI] FETCH_TOURNOI_BEGIN';
export const FETCH_TOURNOI_SUCCESS = '[TOURNOI] FETCH_TOURNOI_SUCCESS';
export const FETCH_TOURNOI_FAILURE = '[TOURNOI] FETCH_TOURNOI_FAILURE';

export const fetchTournoiBegin = () => ({
	type: FETCH_TOURNOI_BEGIN
});

export const fetchTournoiSuccess = tournoi => ({
	type: FETCH_TOURNOI_SUCCESS,
	payload: tournoi
});

export const fetchTournoiFailure = error => ({
	type: FETCH_TOURNOI_FAILURE,
	payload: { error }
});

export function fetchTournoi(slug, activeOnly)
{
    let request = axios.get(process.env.REACT_APP_BACKEND_URL + `/tournois?slug=${slug}&$populate=organisateur${activeOnly ? '&isActive=true':''}`);
    
    return (dispatch) => {
	    dispatch(fetchTournoiBegin());
		
		
		request.then((response) => {
			dispatch(fetchTournoiSuccess(response.data.data[0]));
			return response.data.data[0];
		});
		
    }
}
