import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange'
import FlagIcon from '@material-ui/icons/Flag';
import GroupIcon from '@material-ui/icons/Group';
import RoomIcon from '@material-ui/icons/Room';
import EventIcon from '@material-ui/icons/Event';
import StarIcon from '@material-ui/icons/Star';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import * as PriceUtils from '../utils/priceUtils';
//import moment from 'moment/min/moment-with-locales';
import moment from 'moment'
import 'moment/min/locales'
import Horaire from "../Components/Horaire";
import Tarifs from "../Components/Tarifs";
import MapContainer from "../Components/MapContainer";
import _ from 'lodash'; 
import * as Showdown from "showdown";

const styles = theme => ({
	icon: {
		marginRight: theme.spacing(2),
	},
    '@media print': {
        root: {
            '& h4': {
                fontSize: '1.5rem'
            }
        },
        pageBreakBefore: {
            'page-break-before': 'always'
        },
        avoidPageBreak: {
            'page-break-inside': 'avoid'
        }

    },
    mb16: {
        marginBottom: `${theme.spacing(2)}px`
    }
});


class InfoTournoiPrintable extends Component {

    constructor(props) {
        super(props);
        this.converter = new Showdown.Converter({
            tables: true,
            simplifiedAutoLink: true,
            strikethrough: true,
            tasklists: true
        });
    }

    formatDuAu(du, au) {
    	var debut = moment(du);
    	var fin = moment(au);
    	var formatDebut = "ll";
		var formatFin = "LL";

        if (debut.isSame(fin, 'day')) {
            return "Le " + debut.format("LL"); // 9 novembre 2019
        }

		if (debut.year() === fin.year()) {
			formatDebut = "D MMM"; // 9 nov.
			if (debut.month() === fin.month()) {
				formatDebut = "D" // 9
			}
		}

		return "Du " + debut.format(formatDebut) + " au " + fin.format(formatFin);
    }

    formatParties(tournoi) {
        let result = '';

        if (tournoi) {
            
            // Tournoi avec une seule compétition - on affiche le nombre de parties / groupes de rondes
            if (tournoi.competitions && tournoi.competitions.length === 1) {
                let nbParties = tournoi.competitions.reduce((a, b) => a + b.parties.length, 0);

                if (tournoi.competitions[0].type === "Classique") {
                    result = nbParties > 1 ? nbParties + ' groupes de rondes' : nbParties + ' groupe de rondes';
                } else {
                    result = nbParties > 1 ? nbParties + ' parties' : nbParties + ' partie';
                }
            } else {
                // Plusieurs compétitions, on affiche le nombre de compétitions
                result = `${tournoi.competitions.length} compétitions`;
            }
        }

        return result;
    }

    formatTarifs(tournoi) {
        let result = '';

        if (tournoi) {
            if (tournoi.competitions && tournoi.competitions.length === 1) {
                var compPrincipale = _.find(tournoi.competitions, (c) => c.est_obligatoire);
                if (compPrincipale) {
                    result = PriceUtils.calculateRangeTarifs(compPrincipale).formattedString;
                }
            } else {
                result = 'Tarifs variables (voir plus bas)';
            }
        }       
        return result;
    }

    render() {

        const { classes, tournoi } = this.props;
        
        moment.locale('fr');

        return (<div className={classes.root}>
            <Typography variant={"h4"} align={'center'} className={classes.mb16} gutterBottom>
                Détails du tournoi
            </Typography>
        
            <Grid container spacing={2} className={classes.mb16}>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2} direction="column" >
                        <Grid item>
                            <div className="flex items-center">
                                <DateRangeIcon className={classes.icon}/>
                                <Typography className="h2" color="textPrimary">
                                    {this.formatDuAu(tournoi.date_du, tournoi.date_au)}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className="flex items-center">
                                <FlagIcon className={classes.icon}/>
                                <Typography className="h2" color="textPrimary">
                                    { this.formatParties(tournoi) }
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className="flex items-center">
                                <GroupIcon className={classes.icon}/>
                                <Typography className="h2" color="textPrimary">
                                    {tournoi.contingentement} joueurs maximum
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className="flex items-center">
                                <AttachMoneyIcon className={classes.icon}/>
                                <Typography className="h2" color="textPrimary">
                                    { this.formatTarifs(tournoi) }
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className="flex items-center">
                                <EventIcon className={classes.icon}/>
                                <Typography className="h2" color="textPrimary">
                                    Date limite d'inscription : {moment(tournoi.date_limite_inscription).format('LLL')}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className="flex items-center">
                                <EventIcon className={classes.icon}/>
                                <Typography className="h2" color="textPrimary">
                                    Date limite d'annulation sans pénalité : {moment(tournoi.date_limite_desistement).format('LLL')}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className="flex items-center">
                                <GroupIcon className={classes.icon}/>
                                <Typography className="h2" color="textPrimary">
                                    {tournoi.nb_inscriptions_par_enveloppe} participants par inscription max.
                                </Typography>
                            </div>
                        </Grid>
                        {
                            tournoi.est_benefice_equipe_nationale && 
                            <Grid item>
                                <div className="flex items-center">
                                    <StarIcon className={classes.icon}/>
                                    <Typography className="h2" color="textPrimary">
                                        Tournoi au profit de l'équipe nationale
                                    </Typography>
                                </div>
                            </Grid>
                        }
                        
                        <Grid item>
                            <div className="flex items-center">
                                <RoomIcon className={classes.icon}/>
                                    <Typography className="h2" color="textPrimary">
                                        {tournoi.lieu}
                                    </Typography>
                            </div>
                        </Grid>
                        
                    </Grid>
                </Grid>
                {
                    tournoi && tournoi.lieu_gmaps && (
                        <Grid item xs={12} md={6}>
                            <MapContainer lieu_gmaps={tournoi.lieu_gmaps} />
                        </Grid>
                    )
                }
            </Grid>
        
            
            <div className={classes.pageBreakBefore}>

                
                {
                    tournoi && tournoi.competitions && tournoi.competitions.length > 1 ?
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={6} >
                        <Typography variant={"h4"} align={'center'} gutterBottom className={classes.mt16}>
                        Horaire
                        </Typography>  
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Horaire />
                            </Grid>
                        </Grid>  
                        </Grid>


                        <Grid item xs={12} md={6} >
                        <Typography variant={"h4"} align={'center'} gutterBottom className={classes.mt16}>
                            Tarifs
                        </Typography>  
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Tarifs />
                            </Grid>
                        </Grid>  
                        </Grid>

                        
                        
                    </Grid>
                    :
                    <>
                    <Typography variant={"h4"} align={'center'} gutterBottom className={classes.mt16}>
                        Horaire
                    </Typography>
                    
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Horaire/>
                        </Grid>
                    </Grid>
                    </>
                    
                }
            </div>    

            {tournoi.sections && tournoi.sections.map((section, index) => <div key={index} className={classes.avoidPageBreak}>
                    <Typography variant={"h4"} align={'center'} gutterBottom>
                        {section.titre}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div dangerouslySetInnerHTML={{__html: this.converter.makeHtml(section.content_md)}}>
                            </div>
                        </Grid>
                    </Grid>
                </div>)
                    
                }
    
            {
                (tournoi.modalites || tournoi.est_benefice_equipe_nationale) && <div className={classes.avoidPageBreak}>
                <Typography variant={"h4"} align={'center'} gutterBottom className={classes.mt16}>
                Modalités
            </Typography>
            
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {tournoi.modalites}
                </Grid>
            </Grid>   
            {
                tournoi.est_benefice_equipe_nationale && <Grid item xs={12}>
                Les joueurs A1 qui désirent jouer dans un tournoi bénéfice de l’équipe nationale ne génèrent pas de « pourcentage de série » et ne peuvent en marquer, selon la méthode de classement international. Ils ne peuvent pas non plus marquer de Bonis Podium. Ils ne sont pas admissibles aux trophées, médailles ou autres prix de participation. Ils seront acceptés si le contingentement n’est pas atteint à la date limite d’inscription. Si le contingentement est dépassé à cette date, ils seront placés à la fin de la liste d’attente. Ils ne pourront toutefois être dépassés sur la liste d’attente par les joueurs qui enverront leur inscription plus tard. Leurs parties sont homologuées, mais ils doivent occuper les dernières places dans la salle et ils apparaissent à la fin du classement sur la liste des résultats.
                </Grid>
            }
            </div>
            }
        </div>);
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        
    }, dispatch);
}

function mapStateToProps({tournoi})
{
    return {
        tournoi : tournoi.tournoi,
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InfoTournoiPrintable));
