import * as Actions from '../actions';

const initialState = {
	loading: false,
	isInscriptionSaved: false,
	isAnnulationSaved: false,
    error: null
};

const tournoi = function (state = initialState, action) {
	switch ( action.type )
	{
        case Actions.SAVE_INSCRIPTION_BEGIN:
        {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
	    case Actions.SAVE_INSCRIPTION_SUCCESS:
	    {
		    return {
			    ...state,
				loading: false,
				isInscriptionSaved: true,
				inscription: action.payload
		    };
	    }
	    case Actions.SAVE_INSCRIPTION_FAILURE:
	    {
		    return {
			    ...state,
			    loading: false,
			    error: action.payload.error,
		    };
		}
		case Actions.ANNULER_INSCRIPTION_BEGIN:
        {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
	    case Actions.ANNULER_INSCRIPTION_SUCCESS:
	    {
		    return {
			    ...state,
				loading: false,
				isAnnulationSaved: true,
				inscription: action.payload
		    };
	    }
	    case Actions.ANNULER_INSCRIPTION_FAILURE:
	    {
		    return {
			    ...state,
			    loading: false,
			    error: action.payload.error,
		    };
		}
		case Actions.FETCH_INSCRIPTION_FOR_ANNULATION_BEGIN:
        {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
	    case Actions.FETCH_INSCRIPTION_FOR_ANNULATION_SUCCESS:
	    {
		    return {
			    ...state,
				loading: false,
				inscription: action.payload
		    };
	    }
	    case Actions.FETCH_INSCRIPTION_FOR_ANNULATION_FAILURE:
	    {
		    return {
			    ...state,
			    loading: false,
			    error: action.payload.error,
		    };
	    }
		default:
		{
			return state
		}
	}
};

export default tournoi;

