import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from "react-to-print";
import InscriptionPostalePrintable from './InscriptionPostalePrintable';

const styles = theme => ({ 

  root: {
    marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});

class InscriptionPostale extends Component
{
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <ReactToPrint
          trigger={() => <Button className={classes.button} color="primary" variant="contained" href="#">
            Imprimer cette page
            <PrintIcon className={classes.rightIcon}/>
            </Button>}
          content={() => this.componentRef}
          closeAfterPrint={false}
        />
        <InscriptionPostalePrintable ref={el => (this.componentRef = el)} />
      </div>
    )
  }
}

export default withStyles(styles)(InscriptionPostale);