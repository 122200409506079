import axios from 'axios' ;
import assert from 'assert';

export const FETCH_INSCRIPTIONS = '[INSCRIPTIONS] FETCH_INSCRIPTIONS';
export const FETCH_INSCRIPTIONS_BEGIN = '[INSCRIPTIONS] FETCH_INSCRIPTIONS_BEGIN';
export const FETCH_INSCRIPTIONS_SUCCESS = '[INSCRIPTIONS] FETCH_INSCRIPTIONS_SUCCESS';
export const FETCH_INSCRIPTIONS_FAILURE = '[INSCRIPTIONS] FETCH_INSCRIPTIONS_FAILURE';


export const fetchInscriptionsBegin = () => ({
	type: FETCH_INSCRIPTIONS_BEGIN
});

export const fetchInscriptionsSuccess = inscriptions => ({
	type: FETCH_INSCRIPTIONS_SUCCESS,
	payload: inscriptions
});

export const fetchInscriptionsFailure = error => ({
	type: FETCH_INSCRIPTIONS_FAILURE,
	payload: { error }
});

export function fetchInscriptions(tournoi)
{
	assert(tournoi);
	assert(tournoi._id);
	
    let request = axios.get(`${process.env.REACT_APP_BACKEND_URL}/inscriptions?tournoi=${tournoi._id}&$populate=participants.membre&$limit=500`);
    
    return (dispatch) => {
	    dispatch(fetchInscriptionsBegin());

	    request.then((response) => {
		    dispatch(fetchInscriptionsSuccess(response.data.data));
		    return response.data.data;
	    });
    }
}
