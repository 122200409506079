import React, { Component } from 'react';
import {FormControlLabel, Typography, Checkbox, FormControl, RadioGroup, Radio, Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import * as PriceUtils from '../../../utils/priceUtils';

const styles = theme => ({
    ml16: {
        marginLeft: `${theme.spacing(2)}px`
    },
    ml32: {
        marginLeft: `${theme.spacing(4)}px`
    },
    mb8: {
        marginBottom: `${theme.spacing(1)}px`
    },
    radio: {
        padding: `${theme.spacing(1)}px`
    }    
});

class CompetitionsWidget extends Component {

    //https://blog.sicara.com/custom-nested-forms-validation-javascript-react-2f81cf6aa6e3
    handleFieldChange = (index) => (event, value, selectedKey) => {
        // console.log('ParticipantsWidget handleFieldChange');
        // console.log ({ index, event, value, selectedKey});
        let data = [...this.props.value];
        data[index] = value;

        this.props.onChange(null, data);
    }

    handleCompetitionChecked = (competition) => (event, value, selectedKey) => {
        var selectedCompetitions = [...this.props.value];
        var compIndex = selectedCompetitions.findIndex((c) => c.competition._id === competition._id);

        if (compIndex > -1) {
            selectedCompetitions[compIndex].choix = event.target.checked ? 1 : 0;
        }

        this.props.onChange(null, selectedCompetitions);
    }

    handleRadioChange = (competition) => (event) => {
        var selectedCompetitions = [...this.props.value];
        var compIndex = selectedCompetitions.findIndex((c) => c.competition._id === competition._id);

        if (compIndex > -1) {
            selectedCompetitions[compIndex].choix = parseInt(event.target.value);
        }
        
        this.props.onChange(null, selectedCompetitions);
    }

    render() {
        const allCompetitions = this.props.allCompetitions;
        const selectedCompetitions = this.props.value;
        const participant = this.props.participant;
        const classes = this.props.classes;

        return (
            <Grid container>
            {
            allCompetitions && allCompetitions.map((competition, index) => {
                return <Grid item xs={12} key={competition._id}>
                {
                    !competition.est_payable_sur_place ? 
                        /* Binaire */
                        <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedCompetitions.findIndex(c => c.competition._id === competition._id && c.choix === 1) > -1}
                                onChange={this.handleCompetitionChecked(competition)}
                                value={competition._id}
                                disabled={competition.est_obligatoire}  />
                        }
                        label={`${competition.nom} ${PriceUtils.calculateCompetitionPrice(competition, participant.membre).formattedString}`}
                        
                    />
                    :
                        /* 3 choix */
                        <FormControl component="fieldset"> {/*className={classes.ml32}*/}
                        {/* <FormLabel component="label">{`${competition.nom} ${PriceUtils.calculateCompetitionPrice(competition, participant.membre).formattedString} (Payable sur place)`}</FormLabel> */}
                        <Typography variant="body1" className={classes.mb8}>{`${competition.nom} ${PriceUtils.calculateCompetitionPrice(competition, participant.membre).formattedString}`}</Typography>
                        
                        <div className={classes.ml16}>
                            <RadioGroup
                            aria-label={competition.nom}
                            name={competition.nom}
                            value={selectedCompetitions.findIndex(c => c.competition._id === competition._id) > -1 ? selectedCompetitions.find(c => c.competition._id === competition._id).choix.toString() : "0"}
                            onChange={this.handleRadioChange(competition)}
                            >
                            <FormControlLabel value="0" control={<Radio className={classes.radio} />} label="Non" />
                            <FormControlLabel value="1" control={<Radio className={classes.radio} />} label="Oui" />
                            <FormControlLabel value="2" control={<Radio className={classes.radio} />} label="Peut-être" />
    
                            </RadioGroup>
                        </div>
                      </FormControl>
                    }  
                </Grid>
            })
        }
        </Grid>
        )

    }
}

export default withStyles(styles, {withTheme: true})(CompetitionsWidget);