import React from "react";
import { CardNumberElement, CardExpiryElement, CardCVCElement } from "react-stripe-elements";
import TextField from "@material-ui/core/TextField";
import StripeInput from "./StripeInput";

export function StripeNumberTextField(props) {
  const {
    InputLabelProps,
    InputProps,
    fullWidth = true,
    label = "Numéro de carte",
    labelErrorMessage,
    error,
    margin = "normal",
    ...other
  } = props;
 
  return (
    <TextField
      fullWidth={fullWidth}
      margin={margin}
      label={error ? labelErrorMessage || `Invalid ${label}` : label}
      error={error}
      placeholder=''
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
        required: true
      }}
      InputProps={{
        ...InputProps,
        inputProps: {
          component: CardNumberElement
        },
        inputComponent: StripeInput
      }}
      {...other}
    />
  );
}

export function StripeExpirationTextField(props) {
    const {
      InputLabelProps,
      InputProps,
      fullWidth = true,
      label = "Expiration (MM/AA)",
      labelErrorMessage,
      error,
      margin = "normal",
      ...other
    } = props;
  
    return (
      <TextField
        fullWidth={fullWidth}
        margin={margin}
        label={error ? labelErrorMessage || `Invalid ${label}` : label}
        error={error}
        InputLabelProps={{
          ...InputLabelProps,
          shrink: true,
          required: true
        }}
        placeholder={''}
        InputProps={{
          ...InputProps,
          inputProps: {
            component: CardExpiryElement
          },
          inputComponent: StripeInput
        }}
        {...other}
      />
    );
  }

  export function StripeCvcTextField(props) {
    const {
      InputLabelProps,
      InputProps,
      fullWidth = true,
      label = "CVV",
      labelErrorMessage,
      error,
      margin = "normal",
      ...other
    } = props;
  
    return (
      <TextField
        fullWidth={fullWidth}
        margin={margin}
        helperText="Code à 3 chiffres au dos de la carte"
        label={error ? labelErrorMessage || `Invalid ${label}` : label}
        error={error}
        placeholder=''
        InputLabelProps={{
          ...InputLabelProps,
          shrink: true,
          required: true
        }}
        InputProps={{
          ...InputProps,
          inputProps: {
            component: CardCVCElement
          },
          inputComponent: StripeInput
        }}
        {...other}
      />
    );
  }
