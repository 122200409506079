import React, { Component } from 'react';
import {Elements, StripeProvider} from 'react-stripe-elements';
import AppBar from '@material-ui/core/AppBar';
import BallotIcon from '@material-ui/icons/Ballot';
import { Typography, CssBaseline, Toolbar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link, Route } from 'react-router-dom';
import InfoTournoi from './Pages/InfoTournoi';
import Inscription from './Pages/Inscription';
import InscriptionPostale from './Pages/InscriptionPostale';
import JoueursInscrits from './Pages/JoueursInscrits';
import Annulation from './Pages/Annulation';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from './store/actions';
import './App.css';

const styles = theme => ({
	appBar: {
		position: 'relative',
	},
	bottomAppBar: {	
		top: 'auto',
		bottom: 0,
	},
	icon: {
		marginRight: theme.spacing(2),
	},
	layout: {
		width: 'auto',
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		[theme.breakpoints.up(1100 + theme.spacing(3*2))]: {
			width: 1100,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	footer: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(6),
	},
	pb16: {
		paddingBottom: theme.spacing(2)
	},
	button: {
		margin: theme.spacing(1),
	}
});

class App extends Component {
    state = {
		stripe: null
    }

	componentDidMount()
	{
		this.props.fetchTournoi(this.props.match.params.tournoiSlug, false);

		const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
		const stripeUrl = "https://js.stripe.com/v3/";
		if (!document.querySelector("#stripe-js")) {
		const script = document.createElement("script");
		script.async = true;
		script.id = "stripe-js";
		script.onload = () => {
			const stripe = window.Stripe(stripeKey);
			this.setState({ stripe: stripe });
		};
		document.head.appendChild(script);
		script.src = stripeUrl;
		} else if (window.Stripe) {
		const stripe = window.Stripe(stripeKey);
		this.setState({ stripe: stripe });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.tournoi && this.props.tournoi._id && prevProps.tournoi && prevProps.tournoi._id !== this.props.tournoi._id) {
			this.props.fetchInscriptions(this.props.tournoi);
		}
	}
	

  render() {
	const { classes, tournoi } = this.props;

    return (
      <div className="App">
			
			<div className={classes.pb16}>
	        { 
			//tournoi && !isLoading && 
			(
						<StripeProvider stripe={this.state.stripe}>
		        <Elements>
		        <React.Fragment>
			        <CssBaseline />
			        <AppBar position="static" className={classes.appBar}>
				        <Toolbar>
							
							<Link to={'/' + (tournoi?.slug || this.props.match.params.tournoiSlug)} style={{textDecoration: 'none', color: 'white'}} className="flex flex-1">
									<div className="flex flex-1 items-center">
									<BallotIcon className={classes.icon} />
									<Typography variant="h5" color="inherit" noWrap>
										Inscription - {tournoi?.nom || this.props.match.params.tournoiSlug}
									</Typography>
									</div>
								</Link>
							{/* <div className="flex">
								<Button variant="outlined" color="secondary">
									Liste des joueurs
								</Button>
							</div> */}
				        </Toolbar>
			        </AppBar>

			        <main>
						<Route path={'/:tournoiSlug/inscription'} render={(props) => <Inscription stripe={this.state.stripe} />}/>
						<Route path={'/:tournoiSlug/inscriptionPostale'} component={InscriptionPostale}/>
						<Route path={'/:tournoiSlug/joueurs'} component={JoueursInscrits}/>
						<Route path={'/:tournoiSlug/annulation/:participantId/:annulationGuid'} component={Annulation}/>
						<Route exact strict path={'/:tournoiSlug'} component={InfoTournoi}/>
			        </main>
			        
			        {/* End footer */}
		        </React.Fragment>
		        </Elements>
				</StripeProvider>
	        )}
					</div>
				{/* Footer */}
				{/* <footer>
				<AppBar position="fixed" color="primary" className={classes.bottomAppBar}>
        <Toolbar className={classes.toolbar}>
          <Button href={"#"} className={classes.button}>
						Dépliant
					</Button>
        </Toolbar>
      </AppBar>
				</footer> */}

      </div>
    );
  }
}

// class PrintableInfoTournoi extends React.Component {
// 	render() {
// 		return (
// 			<div>

// 			<ReactToPrint
// 				trigger={() => <a href="#">Print this out!</a>}
// 				content={() => this.componentRef}
// 				/>
// 				<InfoTournoi ref={el => (this.componentRef = el)}/>
// 			</div>
// 		)
// 	}
// }
function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
		fetchTournoi : Actions.fetchTournoi,
		fetchInscriptions: Actions.fetchInscriptions
    }, dispatch);
}

function mapStateToProps({tournoi})
{
    return {
		tournoi : tournoi.tournoi,
		isLoading: tournoi.isLoading
		//loading: tournoi.loading
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App));


