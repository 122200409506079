import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { withStyles } from '@material-ui/core/styles';
import ReactToPrint from "react-to-print";
import { Grid, Typography, Button, Paper, CircularProgress } from '@material-ui/core';
//import moment from 'moment/min/moment-with-locales';
import moment from 'moment'
import 'moment/min/locales'
import InfoTournoiPrintable from './InfoTournoiPrintable';
import classNames from 'classnames';

const styles = theme => ({
	icon: {
		marginRight: theme.spacing(2),
	},
	heroUnit: {
        backgroundColor: theme.palette.background.paper,  
    },
	heroContent: {
		maxWidth: 600,
		margin: '0 auto',
        padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`,
	},
	heroButtons: {
		marginTop: theme.spacing(4),
    },
    circularProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%)'
    },
    '@media print': {
        heroContent: {
            padding: `${theme.spacing(3)}px 0 ${theme.spacing(1)}px`,
            '& h1': {
                fontSize: '1.5rem',
            }
        },
        heroButtons: {
            display: 'none',
        },
        inscriptionButtons: {
            display: 'none',
        },
        paper: {
            'box-shadow': 'none'
        }
    },
    // printBody: {
    //     paddingLeft: '100px',
    //     paddingRight: '100px',
    //     paddingTop: '64px'
    // },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3*2))]: {
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
          padding: theme.spacing(3),
        },
        maxWidth: 960,
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left'
      },
      mt16: {
        marginTop:`${theme.spacing(2)}px`
      }
});

class InfoTournoi extends Component {
    
    render() {

        const { classes, tournoi, isLoading } = this.props;
        const inscriptionsTerminees = (tournoi && 
            tournoi.date_limite_inscription && 
            moment(tournoi.date_limite_inscription).isBefore(moment()));
  
        moment.locale('fr');

        return (
				isLoading ? 
					<div className={classes.circularProgress}>
                        <CircularProgress/>
                    </div>
				 :
        tournoi && <div ref={el => (this.componentRef = el)}>
        <div className={classes.heroUnit}>
        <div className={classes.heroContent}>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                {tournoi.nom}
            </Typography>
            <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
                {tournoi.intro}
            </Typography>
            <div className={classes.heroButtons}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                    <ReactToPrint
                    trigger={() => <Button variant="outlined" color="default">
                        Imprimer
                    </Button>}
                    content={() => this.componentRef}
                    pageStyle={{'margin':'10cm'}}
                        />
                    </Grid>
                    <Grid item>
                        <Button href={`/${tournoi.slug}/joueurs`} variant="outlined" color="default">
                            Liste des joueurs
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    </div>
    {
        inscriptionsTerminees &&
        <Paper className={classes.paper}>
            <div className="flex items-center">
                <Typography className="h2">
                <span role="img" aria-label="warning">⚠️</span> Les inscriptions à ce tournoi sont maintenant terminées. <a href={`mailto:${tournoi.organisateur ? tournoi.organisateur.email : ''}`}>Contacter l'organisateur du tournoi.</a>
                </Typography>
            </div>
                
        </Paper>
    }

    <Paper className={classes.paper}>
    
        <InfoTournoiPrintable />
    
    
        <Grid className={classNames(classes.mt16, classes.inscriptionButtons)} container spacing={2} justifyContent="center">
            <Grid item>
                <Button href={`/${tournoi.slug}/inscription`} variant="contained" color="primary" disabled={inscriptionsTerminees}>
                    Je veux m'inscrire
                </Button>
            </Grid>
            
            {
                tournoi.est_cheque_accepte && 
                <Grid item>
                    <Button href={`/${tournoi.slug}/inscriptionPostale`} variant="outlined" color="default" disabled={inscriptionsTerminees}>
                        Inscription postale
                    </Button>
                </Grid>
            }
            
        </Grid>

            

    </Paper>
    </div>
        )}
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        
    }, dispatch);
}

function mapStateToProps({tournoi})
{
    return {
        tournoi : tournoi.tournoi,
        isLoading: tournoi.isLoading
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InfoTournoi));