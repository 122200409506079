import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel, Checkbox, Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
//import moment from 'moment/min/moment-with-locales';
import moment from 'moment'
import 'moment/min/locales'

const styles = theme => ({
    root: {
        fontSize: 'smaller',
        borderCollapse: 'collapse',
        border: [
            '1px solid black'
          ],
          '& tr': {
            'page-break-inside': 'avoid',
            '-webkit-region-break-inside': 'avoid',
            'page-break-after': 'avoid'
          },
          '& td': {
              border: '1px solid black',
              borderCollapse: 'collapse',
          },
          'page-break-inside': 'avoid',
          'page-break-after': 'auto'
        },
        checkbox: {
            paddingTop: `${theme.spacing(1)/4}px`,
            paddingBottom: `${theme.spacing(1)/4}px`
        },
    });

class Bulletin extends React.Component {

    render() {
        const {tournoi, classes} = this.props;
        moment.locale('fr');
        const competitions = tournoi.competitions;
        const activites = _.filter(tournoi.activites, (a) => a.isActive && a.est_inscription_requise);

        return (
            <div>
                {
        tournoi &&     
            <table className={classes.root}>
                <thead>
                    <tr>
                        <th colSpan="4">
                        Inscription au tournoi {tournoi.nom}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Nom</td>
                        <td colSpan="3"></td>
                    </tr>
                    <tr>
                        <td style={{width: '25%'}}>No membre</td>
                        <td></td>
                        <td style={{width: '25%'}}>Club</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Courriel</td>
                        <td colSpan="3"></td>
                    </tr>
                    <tr>
                        <td>Téléphone</td>
                        <td colSpan="3"></td>
                    </tr>
                    <tr>
                        <td colspan="4">
                        <Typography align={"center"}>
                            Compétitions (cochez vos choix)
                            </Typography>
                            </td>
                    </tr>
                        {
                            competitions.map((c) => 
                            <tr>
                            <td colSpan="4" key={c._id}>
                                <React.Fragment>
                                <Grid container alignContent={'center'} alignItems={'center'} spacing={8}>
                                    <Grid item>
                                        {c.nom}
                                    </Grid>
                                        {
                                            c.est_payable_sur_place ? <Grid item>
                                                {['Oui', 'Non', 'Peut-être'].map((choix, index) => 
                                                    <FormControlLabel
                                                        key={index}
                                                        control={
                                                            <Checkbox
                                                                className={classes.checkbox}
                                                                checked={false}
                                                                value={index.toString()}
                                                                color="primary"
                                                        />
                                                        }
                                                        label={choix}
                                                        />
                                            )}
                                                </Grid> 
                                                :
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                className={classes.checkbox}
                                                                checked={c.est_obligatoire}
                                                                value={c._id}
                                                                color="primary"
                                                                />
                                                        }
                                                        label={''}
                                                        />
                                                </Grid>
                                        }
                                        </Grid>
                                </React.Fragment>
                            </td>
                            </tr>
                            )
                        }
                    
                    {
                        activites.length > 0 && <>
                        <tr>
                            <td colspan="4">
                            <Typography align={"center"}>
                            Activités (cochez vos choix)
                            </Typography>
                            </td>
                        </tr>
                                { activites.map((a) => 
                                <tr><td colspan="4"><FormControlLabel
                                    key={a._id}
                                    control={
                                        <Checkbox
                                            checked={false}
                                            value={a._id}
                                            color="primary"
                                            />
                                    }
                                    label={`${a.nom} - ${a.tarif}`}
                                    /></td></tr>)
                                }
                        </>
                    }
                </tbody>
            </table>
                }
                </div>  
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        
    }, dispatch);
}

function mapStateToProps()
{
    return {
        
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Bulletin));