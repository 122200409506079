import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Bulletins from '../Components/Depliant/Bulletins';
import classNames from 'classnames';

const styles = theme => ({
    layout: {
		width: 'auto',
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		[theme.breakpoints.up(960 + theme.spacing(3*2))]: {
			width: 960,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
    },
    root: {
        padding: theme.spacing(3),
    },
    bold: {
      fontWeight: 'bold'
    }
  });

class InscriptionPostalePrintable extends Component {

    render() {
            const {classes, tournoi, loading} = this.props;
        
            return (
            <div className={classNames(classes.layout, classes.root)}>
            { !loading && <Grid container spacing={12}>
            <Grid item xs={12}>
              <Typography gutterBottom align={'left'}>
                Veuillez imprimer, remplir et découper les bulletins ci-dessous en joignant votre chèque, daté du jour de l'envoi.
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography gutterBottom align={'left'}>
                  Votre chèque doit être fait à l’ordre de <span className={classes.bold}>{tournoi.cheques_ordre_de || (`${tournoi.organisateur.prenom} ${tournoi.organisateur.nom}`)}</span> et posté à l'adresse suivante :
              </Typography>
              </Grid>

              <Grid item xs={12}>
              <Typography align={'left'} gutterBottom>
                {
                  tournoi.destinataire_cheques && <> 
                  {tournoi.destinataire_cheques.split('\n').map((line, index) => <span key={index}>{line}<br/></span>)}
                  </>
                }
                {/* Coorodnnées de l'organisateur */}
                {
                  !tournoi.destinataire_cheques && <>
                  <span>{tournoi.organisateur.prenom} {tournoi.organisateur.nom}<br/></span>
                  {tournoi.organisateur.adresse.split('\n').map((line, index) => <span key={index}>{line}<br/></span>)}
                  </>
                }
                
              </Typography>
              </Grid>

              <Grid item xs={12}>
                <Bulletins tournoi={tournoi} />
              </Grid>
              </Grid>
            }
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
  }
  
  function mapStateToProps({ tournoi }) {
    return {
      tournoi: tournoi.tournoi,
      loading: tournoi.loading
    }
  }
  
  export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InscriptionPostalePrintable));