const assert = require('assert');
const _ = require('lodash');

export function calculateCompetitionPrice(competition, membre) {
    assert(competition);
    assert(membre);
    
    let total = 0;
    let estTarifReduit = false;

    if (competition.est_tarif_reduit && competition.tarifs_reduits && competition.tarifs_reduits.length > 0) {
        let tarifReduit = _.find(competition.tarifs_reduits, (trf) => trf.categorie === membre.cat_fisf);
        if (tarifReduit) {
            total = tarifReduit.tarif;
            estTarifReduit = true;
        }
    } else if (competition.tarif) {
        total = competition.tarif;
    }
    
    // if (Object.keys(competition).findIndex((key) => key === 'est_payable_sur_place') > -1 && 
    //         !competition.est_payable_sur_place && 
    //         membre.is_accepte_office) {
    //     total = 0;
    // }

    return formatTotal({
        total,
        estTarifReduit,
        estPayableSurPlace: competition.est_payable_sur_place,
        is_accepte_office: membre.is_accepte_office
    })
}

export function calculateParticipantTotal(participant) {
    let runningTotal = 0;
    let competitions = (_.filter(participant.competitions, c => !c.competition.est_payable_sur_place && c.choix === 1) || []).map((cc) => cc.competition);
    
    competitions.forEach((c) => {      
        runningTotal += calculateCompetitionPrice(c, participant.membre).total;
    });

    (participant.activites || []).forEach((a) => {
        runningTotal += calculateCompetitionPrice(a, participant.membre).total;
    });

    // Déduire Rabais
    if (participant.rabais && participant.rabais.length > 0) {
        runningTotal -= (_.sum(participant.rabais.map((rabais) => rabais.montant)));
    }
    
    return formatTotal({
        total: runningTotal,
        rabais: participant.rabais,
    })
}

export function calculateInscriptionTotal(inscription) 
{
    let runningTotal = 0;
    (inscription.participants || []).forEach((p) => 
    {
        runningTotal += calculateParticipantTotal(p).total;
    }); 
    return formatTotal({
        total: runningTotal
    })
}

export function calculateRangeTarifs(compOrActi) {
    
    var obj =  {
        formattedString: '',
        formattedString2: [],
        estVariable: false,
    }

    if (!compOrActi) {
        return obj;
    }
    
    if (compOrActi.est_tarif_reduit) {
        var tarifJeune = _.find(compOrActi.tarifs_reduits, (tr) => tr.categorie === 'J');
        var tarifEspoir = _.find(compOrActi.tarifs_reduits, (tr) => tr.categorie === 'E');
        var tarifAdulte = _.find(compOrActi.tarifs_reduits, (tr) => tr.categorie === 'S');
        
        obj.estVariable = true;
        obj.formattedString = `${tarifAdulte ? format(tarifAdulte.tarif) : format(0)} (adulte) - ${tarifEspoir ? format(tarifEspoir.tarif) : format(0)} (espoir) - ${tarifJeune ? format(tarifJeune.tarif) : format(0)} (jeune)`;
        obj.formattedString2 = [`${tarifAdulte ? format(tarifAdulte.tarif) : format(0)} (adulte)`, 
                                `${tarifEspoir ? format(tarifEspoir.tarif) : format(0)} (espoir)`, 
                                `${tarifJeune ? format(tarifJeune.tarif) : format(0)} (jeune)`];
    
    } else {
        obj.formattedString = format(compOrActi.tarif);
        obj.formattedString2 = [ obj.formattedString ];
    }

    return obj;
}

export function appliquerRabais(participant, tournoi) {

    let rabais = null;
    let competitions = (_.filter(participant.competitions, c => !c.competition.est_payable_sur_place && c.choix === 1) || []).map((cc) => cc.competition);
    
     // Rabais
     if (tournoi && tournoi.regles_tarifaires) {
        var regles = _.sortBy(tournoi.regles_tarifaires, (r) => r.priorite);

        rabais = _.find(regles, (r) => {
            // Rabais s'applique si toutes les compétitions ont été sélectionnées
            return ((_.differenceBy(r.competitions, competitions, (c) => c._id.toString())).length === 0);
        });

        if (rabais) {
            // Ne pas permettre un sous-total négatif
            let participantTotalSansRabais = calculateParticipantTotal(participant).total;
            if ((participantTotalSansRabais - rabais.montant) < 0) {
                rabais = null;
            }
        }       
    }

    participant.rabais = rabais ? [rabais] : [];
    
    return participant;
}

function formatTotal(obj) {
    return {
        ...obj,
        formattedString: format(obj.total),
        formattedRabais: (obj.rabais ? obj.rabais.map((r) => format(r.montant)) : null)
    };
}

function format(total) {
    if (total !== undefined) {
        return total.toLocaleString('fr-CA', { style: 'currency', currency: 'CAD'});
    }
    return total;
}

