import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Typography, Grid, Paper, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as Actions from '../store/actions';
import _ from 'lodash';
import "react-table/react-table.css";
import ListeJoueurs from '../Components/ListeJoueurs';


const styles = theme => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3 * 2))]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
        maxWidth: 960,
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left'
    },
    circularProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%)'
    },
});


class JoueursInscrits extends Component {
    state = {
        tabValue: 0
    }

    componentDidMount() {
        if (this.props.tournoi && this.props.tournoi._id) {
            this.props.fetchInscriptions(this.props.tournoi);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.tournoi && this.props.tournoi._id && (prevProps.tournoi !== this.props.tournoi)) {
            this.props.fetchInscriptions(this.props.tournoi);
        }

        if (!_.isEqual(this.props.inscriptions, prevProps.inscriptions) || !_.isEqual(this.props.searchText, prevProps.searchText)) {
            // const data = this.getFilteredArray(this.props.inscriptions, this.props.searchText);
            // this.setState({ data })
        }
    }

    handleTabChange = (event, tabValue) => {
        this.setState({ tabValue });
    };

    // getFilteredArray = (data) => {
    //     var joueurs = [];
    //     data.map((inscription) => {
    //         inscription.participants.map((participant) => {
    //             participant.competitions.map((competition) => {
    //                 joueurs.push({
    //                     inscription: inscription,
    //                     competition: competition,
    //                     participant: participant,
    //                 });
    //             })
    //         })
    //     });
    //     var groupedJoueurs = _.groupBy(_.sortBy(joueurs, 
    //         [
    //             (o) => o.competition.parties && o.competition.parties.length > 0 ? o.competition.parties[0].date_du : new Date(),
    //             (o) => o.inscription.date_reception
    //         ]), 'competition._id');

    //     return groupedJoueurs;
    // };

    getJoueursInscrits = (inscriptions) => {

        var { tournoi } = this.props;

        var joueurs = [];

        inscriptions.map((inscription) => {
            inscription.participants.map((participant) => {
                if (participant.est_actif && (!tournoi.est_tirage_complete || (participant.tirage_rang && participant.tirage_rang <= tournoi.contingentement_ajuste))) {
                    joueurs.push({
                        inscription: inscription,
                        participant: participant,
                    });

                }
                return participant;
            })
            return inscription;
        });

        return _.sortBy(joueurs, (j) => j.participant.membre.club, (j) => j.participant.membre.nom_prenom);
    };

    getListeAttente = (inscriptions) => {

        var { tournoi } = this.props;

        var joueurs = [];
        inscriptions.map((inscription) => {
            inscription.participants.map((participant) => {
                if (participant.est_actif && participant.tirage_rang && participant.tirage_rang > tournoi.contingentement_ajuste) {
                    joueurs.push({
                        inscription: inscription,
                        participant: participant,
                    });

                }
                return participant;
            })
            return inscription;
        });

        return _.sortBy(joueurs, (j) => j.participant.tirage_rang);
    };

    render() {
        const { classes, inscriptions, tournoi, isLoading } = this.props;
        const data = this.getJoueursInscrits(inscriptions);
        const listeAttente = this.getListeAttente(inscriptions);

        const competitions = _.sortBy(tournoi.competitions, [(o) => o.parties && o.parties.length > 0 ? o.parties[0].date_du : new Date()]);
        const estMultiComp = competitions.length > 1;

        return (
            <Paper className={classes.paper}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant={"h4"} align={'left'} gutterBottom>
                            Joueurs acceptés {!tournoi.est_tirage_complete ? '(liste provisoire)' : '(liste officielle)'}
                        </Typography>
                    </Grid>


                    <Grid item xs={12}>
                        {
                            (!tournoi.est_tirage_complete) &&
                            <div>
                                <Typography>
                                    La liste suivante présente les demandes d’inscription reçues jusqu’à maintenant. Cette liste n’est pas officielle tant que le tirage au sort n’a pas été effectué, le cas échéant.
                                </Typography>
                                <br />
                            </div>
                        }
                        <Typography>
                            Vous pouvez trier cette liste en cliquant sur les différents onglets au haut de la liste. Par exemple, si vous souhaitez trier la liste par club, cliquez sur l’onglet « Club » au haut de la liste.
                        </Typography>
                        <br />
                    </Grid>

                    {(isLoading &&
                        <div className={classes.circularProgress}>
                            <CircularProgress />
                        </div>
                    )}
                    {(!isLoading &&

                        <>
                            <Grid item xs={12}>

                                <ListeJoueurs
                                    data={data}
                                    competitions={competitions}
                                    afficherRangs={true}
                                    afficherCompetitions={estMultiComp} />


                            </Grid>

                            {
                                listeAttente.length > 0 && tournoi.est_tirage_complete &&

                                <Grid item xs={12}>
                                    <br />
                                    <br />
                                    <Typography variant={"h4"} align={'left'} gutterBottom>
                                        Liste d'attente
                                    </Typography>
                                    <br />
                                    <div class="listeAttente">
                                        <ListeJoueurs
                                            data={listeAttente}
                                            competitions={competitions}
                                            minRows={5}
                                            afficherRangs={true}
                                            afficherCompetitions={estMultiComp} />
                                    </div>
                                </Grid>
                            }
                        </>

                    )}


                </Grid>
            </Paper>
        );

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchInscriptions: Actions.fetchInscriptions
    }, dispatch);
}

function mapStateToProps({ tournoi, inscriptions }) {
    return {
        tournoi: tournoi.tournoi,
        inscriptions: inscriptions.inscriptions,
        isLoading: inscriptions.isLoading,
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(JoueursInscrits));